import { useMemo } from 'react';
import Lottie from 'react-lottie';

import lottieLoader from './lotties/loader.json';
import { Colors } from 'src/types/colors';

const lottieLoaderBlack = JSON.parse(
  JSON.stringify(lottieLoader).replaceAll('[1,1,1,1]', '[0,0,0,1]')
);

const lottieLoaderBlue = JSON.parse(
  JSON.stringify(lottieLoader).replaceAll('[1,1,1,1]', '[0,0,1,1]')
);

interface LottieLoaderProps {
  height: number;
  width: number;
  color?: Colors;
}

export function LottieLoader({ height, width, color = Colors.White }: LottieLoaderProps) {
  const options = useMemo(() => {
    let animationData;

    switch (color) {
      case Colors.White:
        animationData = lottieLoader;
        break;
      case Colors.Black:
        animationData = lottieLoaderBlack;
        break;
      case Colors.Blue1:
        animationData = lottieLoaderBlue;
        break;
      default:
        throw new Error(`Unsupported loader color: ${color}`);
    }

    return {
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
  }, [color]);

  return <Lottie options={options} height={height} width={width} />;
}
