import { useEffect, useState } from 'react';
import { useConfig } from 'src/components/context/config';

const useDocTitle = (title: string) => {
  const config = useConfig()!;
  const titleWithDealershipName = `${config.dealershipName} | ${title}`;
  const [doctitle, setDocTitle] = useState(titleWithDealershipName);

  useEffect(() => {
    document.title = doctitle;
  }, [doctitle]);

  return [doctitle, setDocTitle];
};

export { useDocTitle };
