import React, { createContext, useContext } from 'react';
import { useLocalStorage, useSessionStorage } from 'react-storage-complete';
import { AppointmentBlock } from 'src/types/appointment-block';
import { CreditApplicationBlock } from 'src/types/creditapplication-block';
import { DocumentBlock } from 'src/types/document-block';
import { GeneralLeadFormattedBlock } from 'src/types/lead';
import { Reference } from 'src/types/reference-block';
import { TradeInResponseBlock } from 'src/types/tradein-block';

interface SessionContextProps {
  sessionAppointments: AppointmentBlock[] | undefined | null;
  setSessionAppointments: (appointments: any) => void;
  sessionCustomer: GeneralLeadFormattedBlock | undefined | null;
  setSessionCustomer: (customer: any) => void;
  sessionLocation: string | undefined | null;
  setSessionLocation: (location: any) => void;
  sessionTradeins: TradeInResponseBlock[] | undefined | null;
  setSessionTradeins: (tradeins: any) => void;
  sessionDocuments: DocumentBlock[] | undefined | null;
  setSessionDocuments: (documents: any) => void;
  sessionCreditApplication: CreditApplicationBlock[] | undefined | null;
  setSessionCreditApplication: (creditApplication: any) => void;
  sessionDesk: any | undefined | null;
  setSessionDesk: (desk: any) => void;
  sessionCreditScore: string | undefined | null;
  setSessionCreditScore: (creditScore: any) => void;
  sessionZip: string | undefined | null;
  setSessionZip: (zip: any) => void;
  tradeinsVisited: boolean | undefined | null;
  setTradeinsVisited: (tradeinsVisited: boolean) => void;
  requireTradeIns: boolean | undefined | null;
  setRequireTradeIns: (requireTradeIns: boolean) => void;
  paymentOptionsVisited: string[] | undefined | null;
  setPaymentOptionsVisited: (paymentOptionsVisited: string[]) => void;
  creditAppVisited: boolean | undefined | null;
  setCreditAppVisited: (creditAppVisited: boolean) => void;
  appointmentsVisited: boolean | undefined | null;
  setAppointmentsVisited: (appointmentsVisited: boolean) => void;
  documentsVisited: boolean | undefined | null;
  setDocumentsVisited: (documentsVisited: boolean) => void;
  localDeals: any[] | undefined | null;
  setLocalDeals: (deals: any) => void;
  sessionReferences: Reference[] | undefined | null;
  setSessionReferences: (references: any) => void;
  localDealerId: string | undefined | null;
  setLocalDealerId: (dealerId: string) => void;
}

const SessionContext = createContext<SessionContextProps | undefined>(undefined);

export const useSessionContext = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSessionContext must be used within a SessionProvider');
  }
  return context;
};

export const SessionProvider: React.FC<{ children: React.ReactNode }> = ({ children }: any) => {
  return (
    <SessionContext.Provider value={{ ...useSessionData() }}>{children}</SessionContext.Provider>
  );
};

export const useSessionData = () => {
  const [sessionAppointments, setSessionAppointments] = useSessionStorage<Array<AppointmentBlock>>(
    'appointments',
    undefined
  ); // Really this is the appointment response block

  const [sessionLocation, setSessionLocation] = useSessionStorage<string>('location', undefined, {
    emitterDisabled: true,
  });
  const [sessionTradeins, setSessionTradeins] = useSessionStorage<Array<TradeInResponseBlock>>(
    'tradeins',
    undefined,
    {
      emitterDisabled: true,
    }
  );
  const [sessionReferences, setSessionReferences] = useSessionStorage<Array<Reference>>(
    'references',
    undefined,
    {
      emitterDisabled: true,
    }
  );
  const [sessionDocuments, setSessionDocuments] = useSessionStorage<Array<any>>(
    'documents',
    undefined,
    {
      emitterDisabled: true,
    }
  );
  const [sessionCreditApplication, setSessionCreditApplication] = useSessionStorage<
    CreditApplicationBlock[]
  >('creditApplication', undefined, {
    emitterDisabled: true,
  });
  const [sessionDesk, setSessionDesk] = useSessionStorage<any>('desk', undefined, {
    emitterDisabled: true,
  });

  const [sessionZip, setSessionZip] = useSessionStorage<string>('zip', undefined);

  const [sessionCreditScore, setSessionCreditScore] = useSessionStorage<string>(
    'creditScore',
    undefined
  );

  const [sessionCustomer, setSessionCustomer] = useLocalStorage<GeneralLeadFormattedBlock>(
    'customer',
    undefined
  );

  const [localDealerId, setLocalDealerId] = useLocalStorage<string>('dealerId', undefined);

  const [tradeinsVisited, setTradeinsVisited] = useLocalStorage<boolean>(
    'tradeinsVisited',
    undefined
  );

  const [requireTradeIns, setRequireTradeIns] = useLocalStorage<boolean>(
    'requireTradeIns',
    undefined
  );
  const [paymentOptionsVisited, setPaymentOptionsVisited] = useLocalStorage<string[]>(
    'paymentOptionsVisitedDeals',
    []
  );
  const [localDeals, setLocalDeals] = useLocalStorage<any[]>('localDeals', []);
  const [creditAppVisited, setCreditAppVisited] = useLocalStorage<boolean>(
    'creditAppVisited',
    false
  );
  const [appointmentsVisited, setAppointmentsVisited] = useLocalStorage<boolean>(
    'appointmentsVisited',
    false
  );
  const [documentsVisited, setDocumentsVisited] = useLocalStorage<boolean>(
    'documentsVisited',
    false
  );

  return {
    sessionAppointments,
    setSessionAppointments,
    sessionCustomer,
    setSessionCustomer,
    sessionLocation,
    setSessionLocation,
    sessionTradeins,
    setSessionTradeins,
    sessionDocuments,
    setSessionDocuments,
    sessionCreditApplication,
    setSessionCreditApplication,
    sessionDesk,
    setSessionDesk,
    sessionCreditScore,
    setSessionCreditScore,
    sessionZip,
    setSessionZip,
    sessionReferences,
    setSessionReferences,
    //Local storage
    localDealerId,
    setLocalDealerId,
    tradeinsVisited,
    setTradeinsVisited,
    requireTradeIns,
    setRequireTradeIns,
    paymentOptionsVisited,
    setPaymentOptionsVisited,
    creditAppVisited,
    setCreditAppVisited,
    appointmentsVisited,
    setAppointmentsVisited,
    documentsVisited,
    setDocumentsVisited,
    localDeals,
    setLocalDeals,
  };
};
