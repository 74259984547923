// MenuContext.tsx
import React, { createContext, useContext, useState, useCallback } from 'react';

interface MenuContextProps {
  isMenuOpen: boolean;
  toggleMenu: () => void;
  isVehicleSelectorOpen: boolean;
  toggleVehicleSelector: () => void;
}

const MenuContext = createContext<MenuContextProps | undefined>(undefined);

export const useMenu = () => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error('useMenu must be used within a MenuProvider');
  }
  return context;
};

export const MenuProvider: React.FC<{ children: React.ReactNode }> = ({ children }: any) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isVehicleSelectorOpen, setIsVehicleSelectorOpen] = useState(false);

  const toggleMenu = useCallback(() => setIsMenuOpen(!isMenuOpen), [isMenuOpen]);
  const toggleVehicleSelector = useCallback(
    () => setIsVehicleSelectorOpen(!isVehicleSelectorOpen),
    [isVehicleSelectorOpen]
  );

  return (
    <MenuContext.Provider
      value={{ isMenuOpen, toggleMenu, isVehicleSelectorOpen, toggleVehicleSelector }}
    >
      {children}
    </MenuContext.Provider>
  );
};
