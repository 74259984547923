import Button from './Button';
import { useFormSubmit } from './Form';

export function FormSkipAction() {
  const { activeStepIndex, showSkipButton } = useFormSubmit();

  const isSkipVisible = showSkipButton && activeStepIndex === 0;

  if (!isSkipVisible) {
    return null;
  }

  return (
    <Button
      baseClass="btn mod-tertiary"
      type="button"
      handleSubmit={e => {
        e?.preventDefault();
        //TODO implement new skip action
        window.alert('Skip button clicked');
      }}
      text="Skip"
    />
  );
}
