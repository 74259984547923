import { useMemo } from 'react';
import './Menu.css';
import {
  faBars,
  faPlus,
  faCars,
  faSearch,
  faPhone,
  faUserCircle,
  faSignOut,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { useConfig } from 'src/components/context/config';
import NavSelector from './selectors/NavSelector';
import { FullBlockRoute } from 'src/types/blocks';
import { useNavigate } from 'react-router-dom';
import { useDeals } from './context/DealsProvider';
import { WebsiteRoute } from 'src/external/WebsiteRoutes';
import { useMenu } from './context/MenuProvider';
import { useCustomer } from 'src/fetches/useCustomer';
import { IconButton } from './form/IconButton';

interface MenuHeaderProps {
  toggleMenu: () => void;
  dealershipLogoUrl: string;
}

export function MenuHeader({ toggleMenu, dealershipLogoUrl }: MenuHeaderProps) {
  const config = useConfig()!;

  return (
    <header className="menu-header">
      <div className="menu-header--desktop">
        <div className="u-hide-mobile-and-ipad-block">
          <a href={`https://${config.websiteDomain}`} className="menu-header--logo">
            <img className="menu-header--logo--img" src={dealershipLogoUrl} alt="Header Logo" />
          </a>
        </div>
        <IconButton
          type="button"
          className="mod-outline"
          iconName={faBars}
          handleSubmit={toggleMenu}
        />
      </div>
    </header>
  );
}

interface DetailsMenuProps {
  dealershipLogoUrl: string;
  toggleMenu: () => void;
  handleLogout: () => void;
}

function DetailsMenu({ dealershipLogoUrl, toggleMenu, handleLogout }: DetailsMenuProps) {
  const config = useConfig()!;
  const navigate = useNavigate();
  const { deal, activeDealId } = useDeals()!;
  const { me, meIsLoading } = useCustomer();

  const navigateVehicles = async () => {
    await toggleMenu();
    window.location.href = `https://${config.websiteDomain}/cars?dealId=${activeDealId}`;
  };

  const navigateToAccount = async () => {
    await toggleMenu();
    navigate(FullBlockRoute.Preferences);
  };

  if (meIsLoading || !me) {
    return null;
  }

  return (
    <>
      <header className="details-menu-header">
        <a href={`https://${config.websiteDomain}`} className="menu-header--logo">
          <img className="menu-header--logo--img" src={dealershipLogoUrl} alt="Header Logo" />
        </a>
        <IconButton className="mod-outline" iconName={faTimes} handleSubmit={toggleMenu} />
      </header>
      <nav className="nav-selector--list">
        <NavSelector
          title="New Reservation"
          btnIcon={faPlus}
          onBtnClick={() => {
            window.location.href = `https://${config.websiteDomain}${WebsiteRoute.CreateDeal}`;
          }}
        />
        {deal && !deal.isDeskingLocked && (
          <NavSelector
            title="Change Vehicles"
            btnIcon={faCars}
            onBtnClick={() => navigateVehicles()}
          />
        )}
        <NavSelector
          title="Search Inventory"
          btnIcon={faSearch}
          onBtnClick={() => {
            window.location.href = `https://${config.websiteDomain}${WebsiteRoute.CreateDeal}`;
          }} // Same Action as New Reservation for now
        />
        <NavSelector
          title="Contact Dealer"
          btnIcon={faPhone}
          onBtnClick={() => {
            window.open(`https://${config.websiteDomain}${WebsiteRoute.Contact}`);
          }}
        />
        <NavSelector
          title="Account"
          btnIcon={faUserCircle}
          onBtnClick={() => navigateToAccount()}
        />
        <NavSelector title="Sign Out" btnIcon={faSignOut} onBtnClick={() => handleLogout()} />
      </nav>
    </>
  );
}

interface MenuProps {
  dealershipLogoUrl: string;
  handleLogout: () => Promise<void>;
}

export default function Menu({ dealershipLogoUrl, handleLogout }: MenuProps) {
  const { isMenuOpen, toggleMenu } = useMenu();

  const renderMainMenuClass = useMemo(() => {
    const classes = ['menu'];
    if (isMenuOpen) classes.push('is-menu-open');
    return classes.join(' ');
  }, [isMenuOpen]);

  const renderDetailsMenuClass = useMemo(() => {
    const classes = ['details-menu'];
    if (isMenuOpen) classes.push('is-menu-open');
    return classes.join(' ');
  }, [isMenuOpen]);

  return (
    <>
      <div className={renderMainMenuClass}>
        <MenuHeader toggleMenu={toggleMenu} dealershipLogoUrl={dealershipLogoUrl}></MenuHeader>
      </div>
      <div className={renderDetailsMenuClass}>
        <DetailsMenu
          toggleMenu={toggleMenu}
          dealershipLogoUrl={dealershipLogoUrl}
          handleLogout={handleLogout}
        />
      </div>
    </>
  );
}
