import { useFormSubmit } from './Form';
import Button from './Button';

export function FormBackAction() {
  const { handleFormGroupBack, activeStepIndex } = useFormSubmit();
  const isFirstBackButtonHidden = activeStepIndex === 0;

  if (isFirstBackButtonHidden) {
    return null;
  }
  return (
    <Button
      baseClass="btn mod-tertiary mod-tall"
      type="button"
      handleSubmit={handleFormGroupBack}
      text="Back"
    />
  );
}
