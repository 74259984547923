import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from './context/AuthProvider';
import { FullBlockRoute } from 'src/types/blocks';

export function RequireAuth({ children }) {
  const { token } = useAuth();
  const location = useLocation();

  if (token === null) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={FullBlockRoute.Login} state={{ from: location }} replace />;
  }

  return children;
}
