import { useMemo } from 'react';
import { BlockStatus } from 'src/types/blocks';
import { useSessionContext } from '../SessionProvider';
import { useReferencesCount } from './referenceCount';
import { useReferences } from 'src/fetches/useReferences';

export const useReferencesStatus = () => {
  const { references } = useReferences();
  const { documentsVisited } = useSessionContext();

  const referenceCount = useReferencesCount();

  return useMemo(() => {
    if (referenceCount === 0) {
      return BlockStatus.Hidden;
    }
    // User has added first name
    if (references && references?.length >= referenceCount) {
      return BlockStatus.Done;
    }

    if (documentsVisited === true) {
      return BlockStatus.Incomplete;
    }

    return BlockStatus.Default;
  }, [references, referenceCount, documentsVisited]);
};
