export function SelectorText({
  title,
  subtitle,
}: {
  title?: string;
  subtitle?: string | JSX.Element;
}) {
  return (
    <div className="selector--text">
      {title && <div className="selector--title">{title}</div>}
      {subtitle && <div className="selector--subtitle">{subtitle}</div>}
    </div>
  );
}
