import { VehicleCondition, VehicleStatus, WebsiteVehicle } from './vehicle';

export enum DealSource {
  Web = 'web',
  Phone = 'phone',
  Walk = 'walk',
  Other = 'other',
}

export enum PaymentType {
  Cash = 'cash',
  Financing = 'financing',
  Leasing = 'leasing',
}

export enum DealStatus {
  Open = 'open',
  Closed = 'closed',
  Unavailable = 'unavailable',
}

interface DealBase {
  condition: VehicleCondition;
  customTags: string[] | null;
  id: string;
  isDeskingLocked: boolean;
  isLeaseEnabled?: boolean;
  location: string;
  make: string;
  manualCustomTags: string[] | null;
  mileage: number;
  model: string;
  msrp: number | null;
  paymentType: PaymentType;
  photo: string;
  price: number;
  sellingPrice: number | null;
  status: DealStatus;
  stockPhoto: string;
  trim: string;
  vehicleStatus: VehicleStatus;
  vin: string | null;
  year: number;
}

export interface SingleDealResponse extends DealBase {
  dealershipId: string;
}

export interface MultipleDealResponse extends DealBase {
  // Are these still in use?
  offerApr: string;
  offerDown: string;
  offerMonthly: string;
  offerMonths: string;
  offerPrice: string;
  offerTrade: string;
}

export interface UnauthorizedDealResponse extends WebsiteVehicle {
  condition: VehicleCondition;
  id: string;
  isDeskingLocked: boolean;
  isLeaseEnabled?: boolean;
  location: string;
  make: string;
  mileage: number;
  model: string;
  paymentType: PaymentType;
  photo: string;
  price: number | null;
  sellingPrice: number | null;
  source: string;
  status: VehicleStatus; // This is the vehicle status, same property name
  stockPhoto: string;
  trim: string;
  vehicleStatus: VehicleStatus;
  vin: string;
  year: number;
}
