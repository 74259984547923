import { ApiError, useApiFetch } from './useApiFetch';
import { useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { AppointmentBlock } from 'src/types/appointment-block';
import { useDeals } from 'src/components/context/DealsProvider';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { useAuth } from 'src/components/context/AuthProvider';

export function useDeskState() {
  const { deal, activeDealId } = useDeals()!;
  const { token } = useAuth();
  const apiFetcher = useApiFetch();
  const unlockKey = `/deals/${activeDealId}/desk-state-unlock`;
  const lockKey = `/deals/${activeDealId}/desk-state-lock`;
  const unauthorizedLockKey = `/unauthorized/desk-state-lock`;
  const deskKey = token ? `deals/${activeDealId}/desk` : `unauthorized/desk`;
  const { mutate } = useSWRConfig();
  const {
    sessionCustomer,
    sessionDesk,
    paymentOptionsVisited,
    setPaymentOptionsVisited,
    setSessionDesk,
    localDeals,
    setLocalDeals,
  } = useSessionContext();

  const toggleState = useCallback(
    async (lock: boolean) => {
      if (!token) {
        return apiFetcher(unauthorizedLockKey, {
          method: 'POST',
          body: JSON.stringify({
            customer: sessionCustomer,
            vin: deal.vin,
          }),
        }).then((resp: any) => {
          setSessionDesk({ ...sessionDesk, [deal.vin as string]: resp });
          if (localDeals && localDeals.some(el => el === deal.vin)) {
            setLocalDeals(
              localDeals.map(
                obj =>
                  obj.vin === deal.vin
                    ? { ...obj, isDeskingLocked: true } // create a new object with z set to yy
                    : obj // keep the object as is
              )
            );
          }
          return resp;
        });
      }
      return apiFetcher(lock ? lockKey : unlockKey, {
        method: 'POST',
      }).then((resp: any) => {
        mutate(deskKey);
        mutate('deals');
        mutate(`deals/${activeDealId}`);

        const newVisited = paymentOptionsVisited?.filter((id: string) => id !== activeDealId);
        setPaymentOptionsVisited(newVisited ?? []);
        return resp;
      });
    },
    [
      apiFetcher,
      unauthorizedLockKey,
      unlockKey,
      lockKey,
      deskKey,
      mutate,
      activeDealId,
      paymentOptionsVisited,
      setPaymentOptionsVisited,
      sessionCustomer,
      sessionDesk,
      setSessionDesk,
      localDeals,
      setLocalDeals,
      deal,
      token,
    ]
  );

  const { data, isLoading, error } = useSWR<AppointmentBlock[], ApiError>(null, apiFetcher);

  return {
    deskState: data,
    deskStateIsLoading: isLoading,
    deskStateIsError: error,
    deskStateUnlock: async () => toggleState(false),
    deskStateLock: async () => toggleState(true),
  };
}
