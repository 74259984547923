import { WidgetThankYouCTA } from 'src/widgets/SpaceWidget';
import { State } from './address';
import { PaymentType } from './deal';

export enum Dms {
  Dealertrack = 'dealertrack',
}

export enum Timezone {
  PST = 'PST',
  MST = 'MST',
  CST = 'CST',
  EST = 'EST',
  ARIZONA = 'ARIZONA',
}

// CRM Mode:
// Full:
// 1. After widget submission, redirect user to portal
// 2. If login widget is opened, check for user session and redirect to portal if user is logged in
// 3. If login widget is opened, show code login form (otherwise, generic checklist)
// 4. If user is logged in and opens payment method widget, redirect to portal
// 5. If full mode, look for dealer or dealership phone number in config - otherwise just use location phone number
// 6. If not in full mode, don't navigate to /vehicles page

export interface DaySchedule {
  fromHours: number | null;
  fromMinutes: number | null;
  isOpen: boolean | null;
  toHours: number | null;
  toMinutes: number | null;
}

export interface WeekSchedule {
  monday?: DaySchedule;
  tuesday?: DaySchedule;
  wednesday?: DaySchedule;
  thursday?: DaySchedule;
  friday?: DaySchedule;
  saturday?: DaySchedule;
  sunday?: DaySchedule;
}

export interface Location {
  id: string;
  name: string;
  email: string;
  phone: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: State;
  zip?: string;
  timezone: Timezone;
  isPriorityLocation: boolean;

  schedule: WeekSchedule;

  deskingConfig?: {
    financing: {
      new: {
        downPaymentPercent: number;
      };
      used: {
        downPaymentPercent: number;
      };
    };
    leasing: {
      new: {
        downPaymentPercent: number;
      };
      used: {
        downPaymentPercent: number;
      };
    };
    general: {
      creditScore: number;
      paymentMethod: PaymentType;
    };
    zip: string;
  };

  dmsLocation?: string | null;
}

export enum AiSuggestionsVoice {
  Friend = 'friend',
  VehicleExpert = 'vehicle-expert',
  LoanExpert = 'loan-expert',
  Sales = 'sales',
}

// add portalDomainReady
export interface Dealership {
  id: string; // unique ID, subdomain for emails
  description: string;
  name: string;
  locations: Location[];

  dms?: Dms | null;
  dmsId?: string | null;
  dmsLocation?: string | null;

  inventoryProvider: string; // Retailing doesn't need to keep track of this
  inventoryLinks?: string; // urls splitted by , for downloading CSV files
  vehicleMatchingRule?: string;

  websiteDomain: string; // e.g. spacedealership.spaceauto.dev
  disableMonitoring: boolean;

  vehicleEditableFields: string[] | null;
  isAutoAssignmentEnabled?: boolean;
  isRelationshipManagementEnabled: boolean;
  portalDomainReady: boolean;

  createdAt?: string;
  isRetailing: boolean;

  dealershipAddress: string;
  dealershipId: string;
  dealershipName: string;

  customTags: string[];
  manualCustomTags: string[];

  virtualPhoneNumber?: string;

  paymentOptionsConfig: {
    disableCash?: boolean;
    disableFinancing?: boolean;
    disableLeasing?: boolean;
  };
  tradeInOptionsConfig?: {
    disableTradeIn?: boolean;
  };

  packages?: {
    desking?: boolean;
    hideprices?: boolean;
    retailing?: boolean;
    crm?: boolean;
    ims?: boolean;
    daisi?: boolean;
    sms?: boolean;
  };

  aiSuggestionsEnabled?: boolean;
  aiSuggestions?: {
    voice: AiSuggestionsVoice;
    tone: string;
    goal: string;
  };
  [key: string]: any;

  forms?: {
    successCta?: WidgetThankYouCTA;
    retailing?: {
      hidePayments?: boolean;
    };
    creditApplication?: {
      pullConsentTitle?: string;
      pullConsentDescription?: string;
      pullConsentTooltip?: string;
      hidePreviousEmployment?: boolean;
      hidePreviousResidence?: boolean;
      showDealerDropdown?: boolean;
      dealerDropdownLabel?: string;
      ssnLabel?: string;
      pullConsentIsHidden?: boolean;
      pullConsentIsHiddenText?: string;
      driversLicenseNumberOptional?: boolean;
      driversLicenseStateOptional?: boolean;
    };
    lead?: {
      requirePhone?: boolean;
      requireSms?: boolean;
      explicitOptIn?: boolean;
      explicitOptInText?: string;
    };
    general?: {
      title?: string;
      body?: string;
    };
  };
  // Custom config
  allowUnauthorizedDocuments?: boolean;

  isAppointmentLocationLockedByVehicle?: boolean;
  requireIncomeProof?: boolean;
  requireReferenceCount?: number;
  requireResidenceProof?: boolean;
}
