export enum DeclarationType {
  Trades = 'addTrades',
  NoTrades = 'noTrades',
}

export const TradeinDeclarationInputs = [
  {
    label: 'Add Trades',
    labelDesc: 'Credit to apply towards your purchase',
    name: 'declaration',
    value: DeclarationType.Trades,
  },
  {
    label: 'No Trades',
    labelDesc: 'Skip this for now',
    name: 'declaration',
    value: DeclarationType.NoTrades,
  },
];
