// MenuContext.tsx
import React, { createContext, useContext, useState, useCallback, useRef, useEffect } from 'react';
import { useConfig } from './config';

interface MinimizeContextProps {
  isMenuOpen: boolean;
  toggleMenu: () => void;
  isModalOpen: boolean;
  closeModal: () => void;
  setIsModalOpen: (submitted?: boolean) => void;
}

const MinimizeContext = createContext<MinimizeContextProps | undefined>(undefined);

export const useMinimize = () => {
  const context = useContext(MinimizeContext);
  if (!context) {
    throw new Error('useMinimize must be used within a MinimizeProvider');
  }
  return context;
};

export const MinimizeProvider: React.FC<{ children: React.ReactNode }> = ({ children }: any) => {
  const config = useConfig()!;
  const [dataAttributes, setDataAttributes] = useState({
    state: config?.chatOptions?.trigger?.isOpen ? 'open' : 'close',
    modalState: config.display?.isModal ? 'open' : 'close',
    submitted: 'false',
  } as any);
  const divRef = useRef<any>(null);

  useEffect(() => {
    const shadowHost = config.container;
    const shadowRoot = shadowHost?.shadowRoot; // Access the shadow root

    if (shadowRoot) {
      const el = shadowRoot.querySelector('#space-widget');
      divRef.current = el;

      if (el) {
        // Function to handle attribute changes
        const handleAttributeChange = () => {
          const modalState = el.getAttribute('data-modal-state');

          const newAttributes = {
            state: el.getAttribute('data-state'),
            modalState,
            submitted: el.getAttribute('data-submitted'),
            // Add more data attributes as needed
          };

          setDataAttributes(newAttributes);
        };

        // Set up the MutationObserver
        const observer = new MutationObserver(handleAttributeChange);

        // Start observing the target element for attribute changes
        observer.observe(el, {
          attributes: true,
          attributeFilter: [
            'data-state',
            'data-height',
            'data-width',
            'data-modal-state',
            'data-submitted',
          ],
        });

        // Initial update
        handleAttributeChange();

        // Clean up the observer on component unmount
        return () => observer.disconnect();
      }
    } else {
      console.warn("No element with id #space-widget found in parent window's document");
    }
    return;
  }, [config]);

  // Function to update the data attributes
  const toggleMenu = useCallback(() => {
    if (divRef.current) {
      divRef.current.setAttribute('data-state', dataAttributes.state === 'open' ? 'close' : 'open');
    }
  }, [dataAttributes]);

  // Function to close modal
  const closeModal = useCallback(() => {
    const documentBody = window.parent.document.body;
    if (documentBody && config.display?.isModal) {
      documentBody.style.overflow = 'auto';
    }

    if (divRef.current) {
      divRef.current.setAttribute('data-modal-state', 'close');
    }
  }, [config]);

  // Function to update the data attributes
  const toggleModal = useCallback(
    (submitted?: boolean) => {
      const documentBody = window.parent.document.body;
      if (documentBody && config.display?.isModal) {
        documentBody.style.overflow = dataAttributes.modalState === 'open' ? 'auto' : 'hidden';
      }

      if (divRef.current) {
        divRef.current.setAttribute(
          'data-modal-state',
          dataAttributes.modalState === 'open' ? 'close' : 'open'
        );

        if (submitted) {
          divRef.current.setAttribute('data-submitted', 'true');
        }

        if (dataAttributes.modalState === 'open' && dataAttributes.submitted === 'true') {
          if (config.reset) {
            config.reset();
          }
        }
      }
    },
    [dataAttributes, config]
  );

  return (
    <MinimizeContext.Provider
      value={{
        isMenuOpen: dataAttributes?.state === 'open',
        toggleMenu,
        isModalOpen: dataAttributes?.modalState === 'open',
        setIsModalOpen: toggleModal,
        closeModal,
      }}
    >
      {children}
    </MinimizeContext.Provider>
  );
};
