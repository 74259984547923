export enum WebsiteRoute {
  CreditApplication = '/finance/credit-application/',
  TradeIn = '/finance/car-trade-in-value/',
  Appointments = '/contact/',
  Documents = '/contact/',
  Contact = '/contact/',
  Faq = '/faqs/',
  Inventory = '/cars/',
  CreateDeal = '/cars?dealId=create',
  PaymentOptions = '/finance/',
  ExploreVehicle = '/cars?dealId=explore',
}

//Website thank you pages generally are something like /thank-you-finance/, /thank-you-appointment/, etc
export const WebsiteThankYouPartialString = 'thank-you';
