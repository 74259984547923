import { useAuth } from 'src/components/context/AuthProvider';
import { useConfig } from 'src/components/context/config';
import { ApiError, useApiFetch } from './useApiFetch';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { useCallback } from 'react';
import { Reference, returnReferenceFormDefaultValues } from 'src/types/reference-block';
import useSWRMutation from 'swr/mutation';
import useSWR from 'swr';

export function useReferences(isPartial: boolean = false) {
  const config = useConfig()!;
  const { token } = useAuth();
  const apiFetcher = useApiFetch();
  const { sessionReferences } = useSessionContext();
  const key = `customers/references`;
  // For no auth, we don't need to look at the /me endpoint
  const referenceCount = config?.requireReferenceCount || 0;

  const deleteReference = useCallback(
    (url, { arg: { id } }) =>
      apiFetcher(`${url}/${id}`, {
        method: 'DELETE',
      }),
    [apiFetcher]
  );
  const { trigger: referencesDelete } = useSWRMutation(key, deleteReference);
  const { data, error } = useSWR<Reference[], ApiError>(token ? key : null, apiFetcher);

  if (!token) {
    if (isPartial) {
      return {
        // Always start from blank data
        references: Array.from({ length: referenceCount }, () =>
          returnReferenceFormDefaultValues()
        ),
        referencesIsLoading: false,
        referencesIsError: undefined,
      };
    }
    return {
      references: sessionReferences ? (sessionReferences as Reference[]) : ([] as Reference[]),
      referencesIsLoading: false,
      referencesIsError: false,
    };
  }

  return {
    references: data
      ? data.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      : [],
    referencesIsLoading: !error && !data,
    referencesIsError: undefined,
    referencesDelete,
  };
}
