import { useConfig } from 'src/components/context/config';
import { ApiError, useApiFetch } from './useApiFetch';
import { useAuth } from 'src/components/context/AuthProvider';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { useCallback } from 'react';
import { DocumentBlock } from 'src/types/document-block';
import { CustomerFormattedBlock } from 'src/types/lead';
import useSWRMutation from 'swr/mutation';
import useSWR from 'swr';

export function useDocuments() {
  const apiFetcher = useApiFetch();
  const config = useConfig()!;
  const { token } = useAuth();
  const key = `customers/documents`;
  const { sessionDocuments, setSessionDocuments, sessionCustomer } = useSessionContext();

  const documentsSubmit = useCallback(
    async (
      blocks: DocumentBlock[],
      customer?: CustomerFormattedBlock
    ): Promise<DocumentBlock[]> => {
      if (!token) {
        return apiFetcher('unauthorized/document', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            origin: `https://${config.websiteDomain}`,
          },
          body: JSON.stringify({
            customer: customer ?? sessionCustomer,
            blocks,
          }),
        }).then((resp: any) => {
          const docs = sessionDocuments?.length ? sessionDocuments : [];
          setSessionDocuments([...docs, ...blocks]);
          return resp;
        });
      }

      return apiFetcher(key, {
        method: 'POST',
        body: JSON.stringify(blocks[0]),
      });
    },
    [
      key,
      apiFetcher,
      sessionCustomer,
      sessionDocuments,
      setSessionDocuments,
      config.websiteDomain,
      token,
    ]
  );

  const deleteDocument = useCallback(
    (url, { arg: id }) =>
      apiFetcher(`${url}/${id}`, {
        method: 'DELETE',
      }),
    [apiFetcher]
  );
  const { trigger: documentsDelete } = useSWRMutation(key, deleteDocument);

  const { data, error, mutate } = useSWR<DocumentBlock[], ApiError>(token ? key : null, apiFetcher);

  if (!token) {
    return {
      documents: sessionDocuments ? sessionDocuments : [],
      documentsIsLoading: false,
      documentsIsError: false,
      documentsMutate: () => Promise.resolve(sessionDocuments ? sessionDocuments : []),
      documentsDelete,
      documentsSubmit: documentsSubmit,
    };
  }

  return {
    documents: data,
    documentsIsLoading: !error && !data,
    documentsIsError: error,
    documentsMutate: mutate,
    documentsDelete,
    documentsSubmit: documentsSubmit,
  } as {
    documents: DocumentBlock[];
    documentsIsLoading: boolean;
    documentsIsError: ApiError;
    documentsMutate: () => Promise<DocumentBlock[]>;
    documentsDelete: (id: string) => Promise<void>;
    documentsSubmit: (
      blocks: DocumentBlock[],
      customer?: CustomerFormattedBlock
    ) => Promise<DocumentBlock[]>;
  };
}
