export enum ComponentState {
  Active = 'is-active',
  Done = 'is-done',
  Loading = 'is-loading',
  Incomplete = 'is-incomplete',
  Disabled = 'is-disabled',
  Blocked = 'is-blocked',
  Open = 'is-open',
  Alert = 'is-alert',
  OrangeAlert = 'is-orange-alert',
  ReadOnly = 'is-read-only',
  Error = 'is-error',
  Success = 'is-success',
}
