export function fireAnalyticsEvents(form: string, step: string) {
  // Only for widget use

  /* Google Analytics */
  if (typeof (window.parent as any).gtag !== 'undefined') {
    (window.parent as any).gtag('event', 'asc_retail_process', {
      event_category: `Space Widget Step`,
      event_label: `${form} - Step ${step}`,
    }) as Function;
  }

  /* Space Analytics */
  if (typeof (window.parent as any).SpaceWebAnalytics !== 'undefined') {
    (window.parent as any).SpaceWebAnalytics.trace({
      action: 'asc_retail_process',
      category: `Space Widget Step`,
      label: `${form} - Step ${step}`,
      page: window?.top?.location.href,
    });
  }
}
