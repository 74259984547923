import { useAuth } from 'src/components/context/AuthProvider';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { ApiError, useApiFetch } from './useApiFetch';
import { useCallback } from 'react';
import useSWRMutation from 'swr/mutation';
import useSWR from 'swr';
import { AppointmentBlock } from 'src/types/appointment-block';

export function useAppointments() {
  const { token } = useAuth();
  const { sessionAppointments } = useSessionContext();
  const apiFetcher = useApiFetch();
  const key = `customers/appointments`;

  const deleteAppointment = useCallback(
    (url, { arg: id }) =>
      apiFetcher(`${url}/${id}`, {
        method: 'DELETE',
      }),
    [apiFetcher]
  );
  const { trigger: appointmentsDelete } = useSWRMutation(key, deleteAppointment);
  const { data, error, mutate } = useSWR<AppointmentBlock[], ApiError>(
    token ? key : null,
    apiFetcher
  );

  if (!token) {
    return {
      appointments: sessionAppointments ?? [],
      appointmentsIsLoading: false,
      appointmentsIsError: undefined,
      appointmentsMutate: mutate,
      appointmentsDelete,
    };
  }

  return {
    appointments: data || [],
    appointmentsIsLoading: !error && !data,
    appointmentsIsError: error,
    appointmentsMutate: mutate,
    appointmentsDelete,
  };
}
