import { useMemo } from 'react';
import { BlockStatus } from 'src/types/blocks';
import { useSessionContext } from '../SessionProvider';
import { useAuth } from '../AuthProvider';
import { useConfig } from '../config';
import { returnDocumentsAreEnabled } from 'src/constants/documents';
import { DocumentTag } from 'src/types/document-block';
import { useDocuments } from 'src/fetches/useDocuments';

export const useDriversLicenseFrontStatus = () => {
  const config = useConfig()!;
  const { documents } = useDocuments();
  const { documentsVisited } = useSessionContext();
  const { token } = useAuth();

  return useMemo(() => {
    if (!returnDocumentsAreEnabled(token, config)) {
      return BlockStatus.Hidden;
    }

    if (documents?.some(item => item.tag === DocumentTag.DriversLicenseFront)) {
      return BlockStatus.Done;
    }

    // User has been to documents page
    if (documentsVisited === true) {
      return BlockStatus.Incomplete;
    }

    return BlockStatus.Default;
  }, [documentsVisited, config, token, documents]);
};
