import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Colors } from 'src/types/colors';
import './Thumbnail.css';
import { useMemo } from 'react';
import { useComponentState } from '../context/ComponentStateProvider';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { useIconColor } from '../hooks/useIconColor';
import { useIconBorderColor } from '../hooks/useIconBorderColor';
import { ComponentState } from 'src/types/component';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { SelectorListType } from './Selector';

export const ThumbnailIcon = ({ icon, hasRing }: { icon: IconDefinition; hasRing: boolean }) => {
  const { state } = useComponentState();
  const color = useIconColor(state);
  const borderColor = useIconBorderColor(state);

  const renderClass = useMemo(() => {
    const classes = ['thumbnail--icon'];

    if (hasRing) {
      classes.push('mod-ring');
    }

    return classes.join(' ');
  }, [hasRing]);

  return (
    <div
      className={renderClass}
      style={{
        borderColor: hasRing ? borderColor : 'transparent',
        background: state.includes(ComponentState.Done) ? Colors.Green1 : 'none',
      }}
    >
      {state.includes(ComponentState.Disabled) && hasRing && (
        <div className="thumbnail--icon--disabled--holder">
          <FontAwesomeIcon icon={faExclamationCircle} size="1x" color={Colors.White} />
        </div>
      )}
      <FontAwesomeIcon icon={icon} color={color} size="1x" />
    </div>
  );
};

export interface ThumbnailProps {
  icon?: IconDefinition;
  iconBackground?: Colors;
  hasIconRing?: boolean;
  element?: React.ReactNode;
  isWide?: boolean;
}

export function Thumbnail({
  thumbnail,
  listType = 'card',
}: {
  thumbnail?: ThumbnailProps;
  listType?: SelectorListType;
}) {
  const renderClass = useMemo(() => {
    const classes = [`thumbnail ${thumbnail?.isWide ? 'mod-wide' : ''}`];

    if (thumbnail?.hasIconRing) {
      classes.push('mod-icon-ring');
    }

    if (listType === 'row') {
      classes.push('is-row-thumbnail');
    }

    return classes.join(' ');
  }, [thumbnail, listType]);

  if (!thumbnail) {
    return null;
  }

  return (
    <div className={renderClass} style={{ backgroundColor: thumbnail.iconBackground ?? 'inherit' }}>
      {thumbnail.element ? (
        thumbnail.element
      ) : (
        <ThumbnailIcon icon={thumbnail.icon!} hasRing={thumbnail?.hasIconRing || false} />
      )}
    </div>
  );
}
