// @ts-nocheck
import { useNavigate } from 'react-router-dom';
import { AuthService } from '../services/Auth';
import { useConfig } from 'src/components/context/config';
import { useAuth } from 'src/components/context/AuthProvider';
import { HOSTED_ZONE_NAME } from '../constants/env';
import { FullBlockRoute } from 'src/types/blocks';

export interface ApiError extends Error {
  status: number;
  info: any;
}

export function useApiFetch() {
  const { websiteDomain, baseApi, dealershipId } = useConfig()!;
  const { signout, token } = useAuth();

  return async (path: string, options: RequestInit = {}) => {
    const authHeaders = token
      ? {
          token: await AuthService.getToken(), // Always get latest token
          'incognito-token': AuthService.getIncognitoToken(),
        }
      : {
          'incognito-token': AuthService.getIncognitoToken(),
        };

    const resp = await fetch(`${baseApi}/${path}`, {
      method: 'GET',
      ...options,
      headers: {
        'Content-Type': 'application/json',
        dealership: dealershipId,
        ...authHeaders,
        ...options?.headers,
      },
    });
    const json = await resp.json();

    if (resp.status === 401 && token) {
      signout(() => (window.location.href = `https://portal.${websiteDomain}/login`));
    }

    if (!resp.ok) {
      const error: ApiError = new Error(json.message || `An error has occured: ${resp.status}`);
      error.info = json;
      error.status = resp.status;
      throw error;
    }

    return json;
  };
}

export function useApiPostFetch() {
  const navigate = useNavigate();
  const { baseApi, dealershipId } = useConfig()!;
  const { signout } = useAuth();

  return async (path, options, withAuth = true) => {
    const authHeaders = withAuth
      ? {
          token: await AuthService.getToken(),
          'incognito-token': AuthService.getIncognitoToken(),
        }
      : null;

    const resp = await fetch(`${baseApi}/${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        dealership: dealershipId,
        ...authHeaders,
      },
      ...options,
    });

    const json = await resp.json();

    if (resp.status === 401) {
      signout(() => navigate(FullBlockRoute.Login));
    }

    if (!resp.ok) {
      const error: ApiError = new Error(json.message || `An error has occured: ${resp.status}`);
      error.info = json;
      error.status = resp.status;
      throw error;
    }

    return json;
  };
}

export function useSearchApiFetch() {
  const { dealershipId } = useConfig()!;

  return async (path, options) => {
    const resp = await fetch(`https://search-api.${HOSTED_ZONE_NAME}/vehicles/${path}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        dealership: dealershipId,
      },
      ...options,
    });
    const json = await resp.json();

    if (!resp.ok) {
      const error: ApiError = new Error(json.message || `An error has occured: ${resp.status}`);
      error.info = json;
      error.status = resp.status;
      throw error;
    }

    return json;
  };
}

export function useWebsiteFetch() {
  const { websiteDomain } = useConfig()!;

  return async (path, options) => {
    const resp = await fetch(`https://${websiteDomain}/wp-json/space-multisite/v1/api/${path}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      ...options,
    });
    const json = await resp.json();

    if (!resp.ok) {
      const error: ApiError = new Error(json.message || `An error has occured: ${resp.status}`);
      error.info = json;
      error.status = resp.status;
      throw error;
    }

    return json;
  };
}
