import { State } from './address';
import { EmploymentStatus } from './employmentStatus';

export interface Employer {
  employer: string;
  employmentStatus: EmploymentStatus | string;
  jobTitle?: string;
  monthlyIncome?: number;
  employmentMonths: number;
  employmentYears: number;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: State | string;
  zip: string;
  phone: string | null;
}

export const nullEmployment = {
  employer: null,
  employerAddressLine1: null,
  employerAddressLine2: null,
  employerState: null,
  employerCity: null,
  employerZip: null,
  jobTitle: null,
  monthlyIncome: null,
  employerPhone: null,
  employmentYears: null,
  employmentMonths: null,
};

export const nullPreviousEmployment = {
  previousEmployer: null,
  previousEmployerAddressLine1: null,
  previousEmployerAddressLine2: null,
  previousEmployerState: null,
  previousEmployerCity: null,
  previousEmployerZip: null,
  previousJobTitle: null,
  previousMonthlyIncome: null,
  previousEmployerPhone: null,
  previousEmploymentYears: null,
  previousEmploymentMonths: null,
};
