import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useConfig } from 'src/components/context/config';
import { useDeals } from 'src/components/context/DealsProvider';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { UnauthorizedDealResponse } from 'src/types/deal';
import { Location } from 'src/types/dealership';
import { SelectOption } from 'src/widgets/SpaceWidget';

// Define the context type
interface LeadLocationContextType {
  location: string;
  locationForAppointment: string;
  locationFixedByVehicle: Location;
  locations: { name: string }[];
  locationIsMultilocation: boolean;
  locationIsHiddenForUser: boolean;
  locationIsUndetermined: boolean;
  locationOfAuthorizedCustomer: Location;
  locationDropdownList: SelectOption[];
}

// Create the context
const LeadLocationContext = createContext<LeadLocationContextType | undefined>(undefined);

// Provider component
export const LocationProvider = ({ children }: { children: ReactNode }) => {
  const { deal } = useDeals();
  const config = useConfig()!;
  const { sessionLocation } = useSessionContext();

  let location: string = '';
  let dropdownList: SelectOption[] = [];

  config.locations.forEach(item => {
    if (config.hideLocations?.includes(item.name)) return;
    dropdownList.push({ value: item.name, label: item.name });
  });

  const isSingleLocation = config.locations.length === 1;

  // Single location dealerships should always just be the one location
  if (isSingleLocation) {
    location = config.locations[0].name;
  }

  // If location is undefined, it wasn't set in the widget config.
  // - So use either the most recent stored location (session location), or an empty string
  // --- Empty string means that if it's a multilocation dealer, the user will have to select a location
  // If a manual location is set, use that (string or null)
  // - manual location can be null
  if (config.location) {
    // TODO: flag to hide location dropdown as well
    location = config.location;
  }

  if (config.location === undefined) {
    // Assign location from session if we have it
    location = sessionLocation || config.locations[0].name;
  }

  if (config.location === null) {
    // Hide location selection from user
    // ddon't assign location, because it's always being set by either deal or fallback.
  }

  // If it's a widget form with a vehicle attached, (and manual location is not set, use the location from the vehicle)
  // - at this point, assume that this is a noauth context
  // - at the moment, location is always forcefully set by Kyle.
  if ((deal as UnauthorizedDealResponse)?.dealershipName) {
    location = (deal as UnauthorizedDealResponse).dealershipName;
  }

  const authorizedCustomerLocation = useMemo(() => {
    return config.locations.find(loc => loc.name === location) || config.locations[0];
  }, [location, config.locations]);

  // Get the location of the vehicle if it exists, or the first location in the list (extreme fallback)
  // Useful for things like the payment calculator where we need to know the location of the vehicle
  const locationFixedByVehicle = useMemo(() => {
    return (
      config.locations.find(
        // search by either dealershipName (search vehicle) or location (Deal prop)
        loc => loc.name === (deal as UnauthorizedDealResponse).dealershipName || deal.location
      ) || config.locations[0]
    );
  }, [deal, config]);

  const locationForAppointment = useMemo(() => {
    // How do we determine location for any given appointment?
    // If the apt exists, it has a location ( ignore in this context, we are just intereseted in new apts)
    // If the apt is new:
    // 1. If single location dealer, that is the location.
    if (isSingleLocation) {
      return config.locations[0].name;
    }
    // 2. If location is predefined in the widget, that is the location.
    if (config.location) {
      return config.location;
    }
    // 3. If there is a vehicle attached to the form, use the vehicle's location
    // 5. If brand new user, use the first location in the list

    // Should return the location of the vehicle if it exists, or the first location in the list (no vehicle)
    return locationFixedByVehicle.name;
  }, [config, isSingleLocation, locationFixedByVehicle.name]);

  return (
    <LeadLocationContext.Provider
      value={{
        location,
        locationForAppointment,
        locationFixedByVehicle,
        locations: config.locations,
        locationIsMultilocation: !isSingleLocation,
        locationIsHiddenForUser: isSingleLocation || config.location === null,
        locationIsUndetermined: config.location === null,
        locationOfAuthorizedCustomer: authorizedCustomerLocation,
        locationDropdownList: dropdownList,
      }}
    >
      {children}
    </LeadLocationContext.Provider>
  );
};

// Hook to use the context
export const useLeadLocationContext = () => {
  const context = useContext(LeadLocationContext);
  if (context === undefined) {
    throw new Error('useLeadLocationContext must be used within a LocationProvider');
  }
  return context;
};
