import { isValidPhoneNumber } from 'react-phone-number-input';
import isMatch from 'date-fns/isMatch';

import { State } from 'src/types/address';
import { format } from 'date-fns';
export const nameRegex = /^[^0-9,'"?!;:#$%&()*+/<>=@[\]\\^_{}|~]+$/;
// const DISALLOWED_SSNS = [
//   ...Array.from({ length: 10 }).map((_, i) => i.toString().repeat(9)),
//   '123456789',
// ];
export const validSsnRegex = /^(?!(000|666|9))\d{3}(?!00)\d{2}(?!0000)\d{4}$/;
export const filledSsnRegex = /^\*\*\*-\*\*-\d{4}$/;
export const nineDigitRegex = /^\d{9}$/;
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const phoneRegex = /^(\+)?\d{10}$/i;
export const numberRegex = /^\d+$/;
export const siteDateFormat = 'MM/dd/yyyy';

export function isValidAgreement(value) {
  return value === 'true';
}

export function isValidText(value) {
  return value !== '';
}

export function isValidDateObject(date: Date) {
  return date instanceof Date && !isNaN(date.getTime());
}

export function isValidBirthDate(value) {
  const userDate = new Date(value).getFullYear();

  return isMatch(value, siteDateFormat) && 1899 < userDate && userDate < new Date().getFullYear();
}

export function isValidAppointmentDate(date) {
  return date > new Date();
}

export function isValidName(value) {
  return nameRegex.test(value) && value !== '';
}

export function isValidOptionalName(value) {
  return value === '' || nameRegex.test(value);
}

export function isValidPhone(value) {
  return isValidPhoneNumber(value, 'US') && phoneRegex.test(value);
}

export function isValidEmail(value) {
  return emailRegex.test(value);
}

export function isValidFullSsn(value) {
  return value !== '' && nineDigitRegex.test(value);
}

export function isValidPartialSsn(value) {
  return value !== '' && (value.length === 9 || filledSsnRegex.test(value));
}

export function isInteger(value) {
  return numberRegex.test(value);
}

export function isValidIncomeAmount(value) {
  return numberRegex.test(value) && value > 0 && value <= 999_999;
}

export function isValidOptionalIncomeAmount(value) {
  return value === '' || isValidIncomeAmount(value);
}

export function isValidVin(value) {
  return typeof value === 'string' && value.length === 17;
}

export function isZipInState(zip: string, state: State) {
  if (!state || !zip) return true;

  const zipPrefix = +zip.substring(0, 3);

  switch (state) {
    case State.AL:
      return zipPrefix >= 350 && zipPrefix <= 369;
    case State.AK:
      return zipPrefix >= 995 && zipPrefix <= 999;
    case State.AZ:
      return zipPrefix >= 850 && zipPrefix <= 869;
    case State.AR:
      return zipPrefix >= 716 && zipPrefix <= 729;
    case State.CA:
      return zipPrefix >= 900 && zipPrefix <= 961;
    case State.CO:
      return zipPrefix >= 800 && zipPrefix <= 816;
    case State.CT:
      return zipPrefix >= 60 && zipPrefix <= 69;
    case State.DE:
      return zipPrefix >= 197 && zipPrefix <= 199;
    case State.DC:
      return zipPrefix >= 200 && zipPrefix <= 205;
    case State.FL:
      return zipPrefix >= 320 && zipPrefix <= 349;
    case State.GA:
      return (zipPrefix >= 300 && zipPrefix <= 319) || (zipPrefix >= 398 && zipPrefix <= 399);
    case State.HI:
      return zipPrefix >= 967 && zipPrefix <= 968;
    case State.ID:
      return zipPrefix >= 832 && zipPrefix <= 838;
    case State.IL:
      return zipPrefix >= 600 && zipPrefix <= 629;
    case State.IN:
      return zipPrefix >= 460 && zipPrefix <= 479;
    case State.IA:
      return zipPrefix >= 500 && zipPrefix <= 528;
    case State.KS:
      return zipPrefix >= 660 && zipPrefix <= 679;
    case State.KY:
      return zipPrefix >= 400 && zipPrefix <= 427;
    case State.LA:
      return zipPrefix >= 700 && zipPrefix <= 714;
    case State.ME:
      return zipPrefix >= 39 && zipPrefix <= 49;
    case State.MD:
      return zipPrefix >= 206 && zipPrefix <= 219;
    case State.MA:
      return (zipPrefix >= 10 && zipPrefix <= 27) || zipPrefix === 55;
    case State.MI:
      return zipPrefix >= 480 && zipPrefix <= 499;
    case State.MN:
      return zipPrefix >= 550 && zipPrefix <= 567;
    case State.MS:
      return zipPrefix >= 386 && zipPrefix <= 397;
    case State.MO:
      return zipPrefix >= 630 && zipPrefix <= 658;
    case State.MT:
      return zipPrefix >= 590 && zipPrefix <= 599;
    case State.NE:
      return zipPrefix >= 680 && zipPrefix <= 693;
    case State.NV:
      return zipPrefix >= 889 && zipPrefix <= 898;
    case State.NH:
      return zipPrefix >= 30 && zipPrefix <= 39;
    case State.NJ:
      return zipPrefix >= 70 && zipPrefix <= 89;
    case State.NM:
      return zipPrefix >= 870 && zipPrefix <= 884;
    case State.NY:
      return (zipPrefix >= 100 && zipPrefix <= 149) || zipPrefix === 5 || zipPrefix === 63;
    case State.NC:
      return zipPrefix >= 270 && zipPrefix <= 289;
    case State.ND:
      return zipPrefix >= 580 && zipPrefix <= 588;
    case State.OH:
      return zipPrefix >= 430 && zipPrefix <= 459;
    case State.OK:
      return zipPrefix >= 730 && zipPrefix <= 749;
    case State.OR:
      return zipPrefix >= 970 && zipPrefix <= 979;
    case State.PA:
      return zipPrefix >= 150 && zipPrefix <= 196;
    case State.RI:
      return zipPrefix >= 28 && zipPrefix <= 29;
    case State.SC:
      return zipPrefix >= 290 && zipPrefix <= 299;
    case State.SD:
      return zipPrefix >= 570 && zipPrefix <= 577;
    case State.TN:
      return zipPrefix >= 370 && zipPrefix <= 385;
    case State.TX:
      return (zipPrefix >= 750 && zipPrefix <= 799) || zipPrefix === 885;
    case State.UT:
      return zipPrefix >= 840 && zipPrefix <= 847;
    case State.VT:
      return zipPrefix >= 50 && zipPrefix <= 59 && zipPrefix !== 55;
    case State.VA:
      return (zipPrefix >= 220 && zipPrefix <= 246) || zipPrefix === 201;
    case State.WA:
      return zipPrefix >= 980 && zipPrefix <= 994;
    case State.WV:
      return zipPrefix >= 247 && zipPrefix <= 268;
    case State.WI:
      return zipPrefix >= 530 && zipPrefix <= 549;
    case State.WY:
      return zipPrefix >= 820 && zipPrefix <= 831;
    default:
      return true;
  }
}

export function getStateFromZip(zip: string) {
  const zipPrefix = +zip.substring(0, 3);

  if (zipPrefix >= 350 && zipPrefix <= 369) return State.AL;
  if (zipPrefix >= 995 && zipPrefix <= 999) return State.AK;
  if (zipPrefix >= 850 && zipPrefix <= 869) return State.AZ;
  if (zipPrefix >= 716 && zipPrefix <= 729) return State.AR;
  if (zipPrefix >= 900 && zipPrefix <= 961) return State.CA;
  if (zipPrefix >= 800 && zipPrefix <= 816) return State.CO;
  if (zipPrefix >= 60 && zipPrefix <= 69) return State.CT;
  if (zipPrefix >= 197 && zipPrefix <= 199) return State.DE;
  if (zipPrefix >= 200 && zipPrefix <= 205) return State.DC;
  if (zipPrefix >= 320 && zipPrefix <= 349) return State.FL;
  if ((zipPrefix >= 300 && zipPrefix <= 319) || (zipPrefix >= 398 && zipPrefix <= 399))
    return State.GA;
  if (zipPrefix >= 967 && zipPrefix <= 968) return State.HI;
  if (zipPrefix >= 832 && zipPrefix <= 838) return State.ID;
  if (zipPrefix >= 600 && zipPrefix <= 629) return State.IL;
  if (zipPrefix >= 460 && zipPrefix <= 479) return State.IN;
  if (zipPrefix >= 500 && zipPrefix <= 528) return State.IA;
  if (zipPrefix >= 660 && zipPrefix <= 679) return State.KS;
  if (zipPrefix >= 400 && zipPrefix <= 427) return State.KY;
  if (zipPrefix >= 700 && zipPrefix <= 714) return State.LA;
  if (zipPrefix >= 39 && zipPrefix <= 49) return State.ME;
  if (zipPrefix >= 206 && zipPrefix <= 219) return State.MD;
  if ((zipPrefix >= 10 && zipPrefix <= 27) || zipPrefix === 55) return State.MA;
  if (zipPrefix >= 480 && zipPrefix <= 499) return State.MI;
  if (zipPrefix >= 550 && zipPrefix <= 567) return State.MN;
  if (zipPrefix >= 386 && zipPrefix <= 397) return State.MS;
  if (zipPrefix >= 630 && zipPrefix <= 658) return State.MO;
  if (zipPrefix >= 590 && zipPrefix <= 599) return State.MT;
  if (zipPrefix >= 680 && zipPrefix <= 693) return State.NE;
  if (zipPrefix >= 889 && zipPrefix <= 898) return State.NV;
  if (zipPrefix >= 30 && zipPrefix <= 39) return State.NH;
  if (zipPrefix >= 70 && zipPrefix <= 89) return State.NJ;
  if (zipPrefix >= 870 && zipPrefix <= 884) return State.NM;
  if ((zipPrefix >= 100 && zipPrefix <= 149) || zipPrefix === 5 || zipPrefix === 63)
    return State.NY;
  if (zipPrefix >= 270 && zipPrefix <= 289) return State.NC;
  if (zipPrefix >= 580 && zipPrefix <= 588) return State.ND;
  if (zipPrefix >= 430 && zipPrefix <= 459) return State.OH;
  if (zipPrefix >= 730 && zipPrefix <= 749) return State.OK;
  if (zipPrefix >= 970 && zipPrefix <= 979) return State.OR;
  if (zipPrefix >= 150 && zipPrefix <= 196) return State.PA;
  if (zipPrefix >= 28 && zipPrefix <= 29) return State.RI;
  if (zipPrefix >= 290 && zipPrefix <= 299) return State.SC;
  if (zipPrefix >= 570 && zipPrefix <= 577) return State.SD;
  if (zipPrefix >= 370 && zipPrefix <= 385) return State.TN;
  if ((zipPrefix >= 750 && zipPrefix <= 799) || zipPrefix === 885) return State.TX;
  if (zipPrefix >= 840 && zipPrefix <= 847) return State.UT;
  if (zipPrefix >= 50 && zipPrefix <= 59 && zipPrefix !== 55) return State.VT;
  if ((zipPrefix >= 220 && zipPrefix <= 246) || zipPrefix === 201) return State.VA;
  if (zipPrefix >= 980 && zipPrefix <= 994) return State.WA;
  if (zipPrefix >= 247 && zipPrefix <= 268) return State.WV;
  if (zipPrefix >= 530 && zipPrefix <= 549) return State.WI;
  if (zipPrefix >= 820 && zipPrefix <= 831) return State.WY;
  return undefined;
}

export function convertPhoneForSubmit(phone: string | null) {
  if (phone === '' || phone === null) {
    return null;
  }

  if (phone[0] === '+') {
    return phone;
  }

  return `+1${phone.replace(/[^0-9]/g, '')}`;
}

export function convertBirthDateForSubmit(date) {
  if (date === '' || date === null) {
    return null;
  }

  if (date.includes('-')) {
    // user input date is in mm/dd/yyy format
    return date;
  }

  return format(new Date(date), 'yyyy-MM-dd');
}
