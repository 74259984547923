import { Amplify, Auth } from 'aws-amplify';
import Cookies from 'js-cookie';
import { REGION, USER_POOL_ID, USER_POOL_CLIENT_ID, ENV } from '../constants/env';

const HACK_PASSWORD = 'H@ck_To_W0rk_With_Amplify';

const LOCALHOST_HOSTNAME = 'localhost';
const COOKIE_NAME = `space-insights-token-${ENV}`;

export class AuthService {
  private static tempUser = null;

  static init(websiteDomain: string, hostDomain: string) {
    Amplify.configure({
      Auth: {
        region: REGION,
        userPoolId: USER_POOL_ID,
        userPoolWebClientId: USER_POOL_CLIENT_ID,
        cookieStorage: {
          domain: hostDomain === LOCALHOST_HOSTNAME ? LOCALHOST_HOSTNAME : websiteDomain,
          secure: hostDomain !== LOCALHOST_HOSTNAME,
        },
      },
    });
  }

  private static async signUp(dealershipId: string, email: string) {
    const params = {
      username: `${dealershipId}#${email}`,
      password: HACK_PASSWORD,
      attributes: {
        'custom:dealershipId': dealershipId,
      },
    };

    try {
      await Auth.signUp(params);
    } catch (err) {
      console.info('Already signed up');
    }
  }

  public static async requestCode(dealershipId: string, email: string) {
    await AuthService.signUp(dealershipId, email);

    try {
      AuthService.tempUser = await Auth.signIn(`${dealershipId}#${email}`);
    } catch (e) {
      throw new Error('Please enter an existing email address');
    }
  }

  public static async verifyCode(code: string) {
    if (!AuthService.tempUser) {
      throw new Error('User has not requested a code');
    }

    try {
      await Auth.sendCustomChallengeAnswer(AuthService.tempUser, code);
    } catch (e) {
      throw new Error('The code is incorrect or expired. Please try again to request a code');
    }

    try {
      await Auth.currentSession();
    } catch {
      throw new Error('Code is incorrect');
    }
  }

  public static async getToken() {
    const currentUser = await Auth.currentAuthenticatedUser();
    return currentUser.signInUserSession.accessToken.jwtToken as string;
  }

  public static getIncognitoToken() {
    return Cookies.get(COOKIE_NAME);
  }

  public static async signOut() {
    await Auth.signOut();
  }
}
