import { Colors } from 'src/types/colors';
import { Popover, PopoverTrigger } from './Popover';
import { Tooltip } from './Tooltip';

export function AbsoluteTooltip({ initialOpen, text }) {
  return (
    <Popover initialOpen={initialOpen} placement="right">
      <PopoverTrigger className="popover-tooltip--absolute-trigger"></PopoverTrigger>
      <Tooltip text={text} color={Colors.Blue1} />
    </Popover>
  );
}
