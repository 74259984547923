import { ApiError, useSearchApiFetch } from './useApiFetch';
import useSWR from 'swr';
import { WebsiteVehicle } from 'src/types/vehicle';
import { useConfig } from 'src/components/context/config';

export function useSearchApi(path?: string) {
  const apiFetcher = useSearchApiFetch();
  const config = useConfig()!;
  const preLoadedData = path && config.vehicle?.vin && config.vehicle?.vin === path;
  const { data, error, isLoading } = useSWR<WebsiteVehicle, ApiError>(
    preLoadedData ? null : path ?? null,
    apiFetcher,
    {
      shouldRetryOnError: false,
    }
  );

  // Just returng the passed vehicle if that's the one we need
  if (preLoadedData) {
    return {
      searchVehicle: config.vehicle,
      searchVehicleIsLoading: false,
      searchVehicleIsError: error,
    };
  }

  // Hooks are not ready
  if (!path) {
    return {
      searchVehicle: undefined,
      searchVehicleIsLoading: false,
      searchVehicleIsError: error,
    };
  }

  return {
    searchVehicle: data,
    searchVehicleIsLoading: isLoading,
    searchVehicleIsError: error,
  };
}
