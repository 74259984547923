import { Toaster, toast, ToastBar } from 'react-hot-toast';

export default function Toast() {
  return (
    <Toaster
      containerStyle={{
        position: 'absolute',
      }}
      toastOptions={{
        duration: 8000,
        success: {
          iconTheme: {
            primary: '#34C759',
            secondary: '#ffffff',
          },
          style: {
            color: '#ffffff',
            background: '#34C759',
          },
        },
        error: {
          iconTheme: {
            primary: '#FF3B30',
            secondary: '#ffffff',
          },
          style: {
            color: '#ffffff',
            background: '#FF3B30',
          },
        },
      }}
    >
      {t => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== 'loading' && (
                <button className="toast--dismiss" onClick={() => toast.dismiss(t.id)}></button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}
