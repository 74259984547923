import React, { useMemo } from 'react';
import { SelectorSize, SelectorType } from '../selectors/Selector';

export function useSelectorContainer(
  size: SelectorSize,
  type: SelectorType,
  height: string,
  children: React.ReactNode | React.ReactNode[]
) {
  const style = useMemo(() => {
    let styles: React.CSSProperties = {};

    if (size === 'medium') {
      styles = { minHeight: '48px', height: 'auto' };
    }

    if (size === 'large') {
      styles = { minHeight: children ? 'none' : '64px', height: 'auto' };
    }

    if (type === 'accordion') {
      styles = { height: `${height}px` };
    }
    return styles;
  }, [size, type, height, children]);

  return { style };
}
