import { useAuth } from 'src/components/context/AuthProvider';
import { ApiError, useApiFetch } from './useApiFetch';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { useCallback } from 'react';
import useSWRMutation from 'swr/mutation';
import useSWR from 'swr';
import { CreditApplicationBlock } from 'src/types/creditapplication-block';

export function useCreditApplications() {
  const apiFetcher = useApiFetch();
  const { token } = useAuth();
  const key = `customers/creditapplications`;
  const { sessionCreditApplication } = useSessionContext();

  const deleteCreditApplications = useCallback(
    (url, { arg: id }) =>
      apiFetcher(`${url}/${id}`, {
        method: 'DELETE',
      }),
    [apiFetcher]
  );
  const { trigger: creditApplicationsDelete } = useSWRMutation(key, deleteCreditApplications);
  const swapCreditApplications = useCallback(
    url =>
      apiFetcher(`${url}/swap`, {
        method: 'PATCH',
      }),
    [apiFetcher]
  );
  const { trigger: creditApplicationsSwap } = useSWRMutation(key, swapCreditApplications);
  const { data, error } = useSWR<CreditApplicationBlock[], ApiError>(
    token ? key : null,
    apiFetcher
  );

  if (!token) {
    return {
      creditApplications: sessionCreditApplication ? sessionCreditApplication : [],
      creditApplicationsIsLoading: false,
      creditApplicationsIsError: undefined,
      creditApplicationsDelete,
      creditApplicationsSwap,
    };
  }

  return {
    creditApplications: data || [],
    creditApplicationsIsLoading: !error && !data,
    creditApplicationsIsError: error,
    creditApplicationsDelete,
    creditApplicationsSwap,
  };
}
