import { useState, useEffect, createContext, useContext } from 'react';
import { API_URL } from '../../constants/env';
import { Dealership } from 'src/types/dealership';
import { RenderWidgetParams, WidgetThankYouConfig } from 'src/widgets/SpaceWidget';

export type Config = Partial<RenderWidgetParams> &
  Dealership & {
    isRetailing: boolean;
    hostDomain: string;
    redirectUrl: string;
  };

export const ConfigContext = createContext<Config | null>(null);
const LOCALHOST_HOSTNAME = 'localhost';

function convertRoutes(routes: WidgetThankYouConfig | undefined, websiteDomain: string) {
  let modifiedRoutes = routes || {};
  let websiteOrigin = `https://${websiteDomain}`;

  for (const key in modifiedRoutes) {
    if (modifiedRoutes[key].route) {
      modifiedRoutes[key].route = websiteOrigin + modifiedRoutes[key].route;
    }
  }
  return modifiedRoutes;
}

function getRedirectOrigin(widgetDomain: string, websiteDomain: string) {
  // Widget redirects
  if (widgetDomain !== '') {
    const { hostname } = new URL(widgetDomain);
    const redirectOrigin =
      hostname === LOCALHOST_HOSTNAME ? 'http://localhost:3000' : `https://portal.${websiteDomain}`;
    return redirectOrigin;
  }

  // eslint-disable-next-line no-undef
  const hostDomain = globalThis?.location?.hostname ?? LOCALHOST_HOSTNAME;
  return hostDomain === LOCALHOST_HOSTNAME ? 'http://localhost:3000' : `https://${websiteDomain}`;
}

export function useConfigState(props?: RenderWidgetParams) {
  const [config, setConfig] = useState<Config | null>(null);
  const LOCALHOST_HOSTNAME = 'localhost';

  useEffect(() => {
    let mounted = true;

    //TODO: big performance enhancement to prefetch this data
    Promise.all([fetch(`${API_URL}/config`).then(res => res.json()) as Promise<Dealership>]).then(
      ([dynamicConfig]) => {
        const config: Config = {
          // Start with launch portal props
          ...dynamicConfig,
          // Override with widget props
          ...props,
          // Determine values that could be set in both
          hideLocations:
            props?.hideLocations === undefined
              ? dynamicConfig.hideLocations || []
              : props.hideLocations,
          // This field needs to be moved in the launch portal
          successCta: props?.successCta || dynamicConfig.forms?.successCta,
          // Override with runtime props
          baseApi: API_URL,
          isRetailing: !props,
          hostGlobal: globalThis as typeof globalThis,
          hostDomain: globalThis.location?.hostname || LOCALHOST_HOSTNAME,
          routes: convertRoutes(props?.routes, dynamicConfig.websiteDomain),
          redirectUrl: getRedirectOrigin(props?.widgetDomain || '', dynamicConfig.websiteDomain),
          widgetDomain: props?.widgetDomain || '',
          // Test props here
          // packages: {
          //   desking: true,
          //   hideprices: true,
          //   retailing: true,
          //   crm: true,
          //   ims: true,
          //   daisi: true,
          //   sms: true,
          // },
          //TEST
          forms: {
            retailing: {
              hidePayments: false,
            },
          },
        };
        if (mounted) {
          setConfig(config);
        }
      }
    );

    return function cleanup() {
      mounted = false;
    };
  }, [props]);

  console.log(config);

  return config;
}

export function useConfig() {
  const config = useContext(ConfigContext);
  return config;
}
