import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Banner.css';
import { useDeals } from './context/DealsProvider';
import { faLock } from '@fortawesome/pro-regular-svg-icons';
import { Colors } from 'src/types/colors';
import Button from './form/Button';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMatch } from 'react-router-dom';
import { FullBlockRoute } from 'src/types/blocks';
import { useDeskState } from 'src/fetches/useDeskState';

export function Banner() {
  const { deal } = useDeals();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { deskStateUnlock } = useDeskState();
  const showBanner = useMatch({
    path: `/deals/:dealId${FullBlockRoute.Finance}*`,
    end: true,
  });

  // Lock the deal
  const handleUnlock = async () => {
    setIsSubmitting(true);

    try {
      await deskStateUnlock();

      toast.success('Deal unlocked');
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  if (!showBanner || deal.isDeskingLocked === false) return null;

  return (
    <div className="banner mod-black">
      <div className="banner--content">
        <div className="banner--content--icon">
          <FontAwesomeIcon icon={faLock} color={Colors.White} />
        </div>
        <div className="banner--content--text">
          <div className="banner--content--title">Payment Locked</div>
          <div className="banner--content--description">Changes require undoing commitment.</div>
        </div>
        <div className="banner--content--action">
          <Button
            type="button"
            text="Unlock"
            baseClass="btn mod-primary mod-danger-light"
            isSubmitting={isSubmitting}
            handleSubmit={handleUnlock}
          />
        </div>
      </div>
    </div>
  );
}
