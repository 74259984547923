import { useMemo } from 'react';
import { BlockStatus } from 'src/types/blocks';
import { useReferencesCount } from './referenceCount';
import { useAuth } from '../AuthProvider';
import { useConfig } from '../config';
import { returnDocumentsAreEnabled } from 'src/constants/documents';
import { useReferences } from 'src/fetches/useReferences';

export const useReferenceStatus = () => {
  const config = useConfig()!;
  const { token } = useAuth();
  const { references } = useReferences();
  const referenceCount = useReferencesCount();

  return useMemo(() => {
    let array: BlockStatus[] = [];

    if (!returnDocumentsAreEnabled(token, config)) {
      return BlockStatus.Hidden;
    }

    for (let i = 0; i < referenceCount; i++) {
      let reference = references?.[i];

      if (reference) {
        array.push(BlockStatus.Done);
      } else {
        array.push(BlockStatus.Default);
      }
    }

    return array.length ? array : BlockStatus.Hidden;
  }, [references, referenceCount, config, token]);
};
