import { useMemo } from 'react';
import { BlockStatus } from 'src/types/blocks';
import { useDeals } from '../DealsProvider';
import { useSessionContext } from '../SessionProvider';
import { DealStatus } from 'src/types/deal';
import { useAuth } from '../AuthProvider';
import { useAppointments } from 'src/fetches/useAppointments';

export const useAppointmentsStatus = () => {
  const { token } = useAuth();
  const { deal } = useDeals()!;
  const { appointments } = useAppointments();
  const { sessionAppointments, appointmentsVisited } = useSessionContext();

  const hasAppointmentInFuture = useMemo(() => {
    return appointments?.some(appointment => {
      return new Date(appointment.appointmentAt).getTime() > new Date().getTime();
    });
  }, [appointments]);

  return useMemo(() => {
    // User has been to Appointments page and has 1 or more appointments in the future
    if (
      deal.status === DealStatus.Closed ||
      (appointmentsVisited === true && hasAppointmentInFuture)
    ) {
      return BlockStatus.Done;
    }

    if (!token && sessionAppointments) {
      return BlockStatus.Done;
    }

    // User has visited appointments page and has no appointments
    if (appointmentsVisited === true) {
      return BlockStatus.Incomplete;
    }

    return BlockStatus.Default;
  }, [appointmentsVisited, deal, hasAppointmentInFuture, sessionAppointments, token]);
};
