import { useAuth } from 'src/components/context/AuthProvider';
import { ApiError, useApiFetch } from './useApiFetch';
import { useCallback } from 'react';
import useSWRMutation from 'swr/mutation';
import useSWR from 'swr';
import { MultipleDealResponse } from 'src/types/deal';
import { useSessionContext } from 'src/components/context/SessionProvider';

export function useSwrDeals() {
  const { token, signout } = useAuth();
  const apiFetcher = useApiFetch();
  const key = 'deals';
  const { localDeals, setLocalDeals } = useSessionContext();

  const createDeal = useCallback(
    async (url, { arg }: { arg: any }) => {
      if (!token) {
        return setLocalDeals([...(localDeals || []), arg]);
      }
      return apiFetcher(url, {
        method: 'POST',
        body: JSON.stringify(arg),
      });
    },
    [apiFetcher, token, localDeals, setLocalDeals]
  );
  const { trigger: dealsCreate } = useSWRMutation(key, createDeal);

  const deleteDelete = useCallback(
    (url, { arg: id }) =>
      apiFetcher(`${url}/${id}`, {
        method: 'DELETE',
      }),
    [apiFetcher]
  );
  const { trigger: dealsDelete } = useSWRMutation(key, deleteDelete);
  const { data, isLoading, error, mutate } = useSWR<MultipleDealResponse[], ApiError>(
    token ? 'deals' : null,
    apiFetcher
  );

  if (error && [401, 403].includes(error.status)) {
    signout();
  }

  if (!token) {
    return {
      deals: localDeals,
      dealsIsLoading: false,
      dealsIsError: undefined,
      dealsMutate: mutate,
      dealsCreate,
      dealsDelete,
    };
  }

  return {
    deals: data,
    dealsIsLoading: isLoading,
    dealsIsError: error,
    dealsMutate: mutate,
    dealsCreate,
    dealsDelete,
  };
}
