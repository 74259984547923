import { useMemo } from 'react';
import { BlockStatus, DocumentItemWithStatus } from 'src/types/blocks';
import { useSessionContext } from '../SessionProvider';
import { useAuth } from '../AuthProvider';
import { useConfig } from '../config';
import { returnDocumentsAreEnabled } from 'src/constants/documents';

export const useDocumentStatus = (uploadSteps: DocumentItemWithStatus[]) => {
  const config = useConfig()!;
  const { documentsVisited } = useSessionContext();
  const { token } = useAuth();

  return useMemo(() => {
    if (!returnDocumentsAreEnabled(token, config)) {
      return BlockStatus.Hidden;
    }

    if (!uploadSteps.length) return BlockStatus.Default;

    // If all uploadSteps are done, return BlockStatus.Done
    if (uploadSteps.every(step => step.status === (BlockStatus.Done || BlockStatus.Hidden))) {
      return BlockStatus.Done;
    }

    // User has been to documents page
    if (documentsVisited === true) {
      return BlockStatus.Incomplete;
    }

    return BlockStatus.Default;
  }, [uploadSteps, documentsVisited, config, token]);
};
