import { useAuth } from 'src/components/context/AuthProvider';
import { ApiError, useApiFetch } from './useApiFetch';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { useCallback } from 'react';
import useSWRMutation from 'swr/mutation';
import useSWR from 'swr';
import { TradeinBlock } from 'src/types/tradein-block';

export function useTradeins() {
  const apiFetcher = useApiFetch();
  const key = `customers/tradeins`;
  const { token } = useAuth();
  const { sessionTradeins, setSessionTradeins } = useSessionContext();

  const deleteTradein = useCallback(
    (url, { arg: id }) =>
      apiFetcher(`${url}/${id}`, {
        method: 'DELETE',
      }),
    [apiFetcher]
  );
  const { trigger: tradeinsDelete } = useSWRMutation(token ? key : null, deleteTradein);
  const { data, error, mutate } = useSWR<TradeinBlock[], ApiError>(token ? key : null, apiFetcher);

  if (!token) {
    return {
      tradeins: sessionTradeins ? sessionTradeins : [],
      tradeinsIsLoading: false,
      tradeinsIsError: undefined,
      tradeinsMutate: setSessionTradeins,
      tradeinsDelete,
    };
  }

  return {
    tradeins: data || [],
    tradeinsIsLoading: !error && !data,
    tradeinsIsError: error,
    tradeinsMutate: mutate,
    tradeinsDelete,
  };
}
