export enum EmploymentStatus {
  Unemployed = 'unemployed',
  Employed = 'employed',
  Retired = 'retired',
  SelfEmployed = 'selfemployed',
  Other = 'other',
}

export function requiresEmploymentDetails(employmentStatus: EmploymentStatus) {
  return ['employed', 'selfemployed', 'other'].includes(employmentStatus);
}
