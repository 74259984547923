import { useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { useWatch } from 'react-hook-form';
import type { useForm } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

const useAutoSave = (
  context: ReturnType<typeof useForm>,
  onSubmit: (data: unknown) => void,
  enabled: boolean = false
) => {
  const { control, formState, handleSubmit } = context;
  const watch = useWatch({ control, disabled: !enabled });
  const { dirtyFields } = formState;
  const hasDirtyFields = Object.keys(dirtyFields).length > 0;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce(() => {
      handleSubmit(onSubmit)();
    }, 500),
    []
  );

  useEffect(() => {
    // Clean up debounce on unmount
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  useDeepCompareEffect(() => {
    if (enabled && hasDirtyFields) {
      debouncedSave();
    }
  }, [watch, enabled]);
};

export { useAutoSave };
