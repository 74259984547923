// MenuContext.tsx
import React, { createContext, useContext } from 'react';

interface FormPropsContextProps {
  props: any;
}

const FormPropsContext = createContext<FormPropsContextProps | undefined>(undefined);

export const useFormProps = () => {
  const context = useContext(FormPropsContext);
  if (!context) {
    throw new Error('useFormProps must be used within a useFormPropsProvider');
  }
  return context;
};

export const FormPropsProvider: React.FC<{ children: React.ReactNode; props: any }> = ({
  props,
  children,
}: any) => {
  return <FormPropsContext.Provider value={{ props }}>{children}</FormPropsContext.Provider>;
};
