import { convertPhoneForSubmit } from 'src/constants/validation';
import { State, Address } from './address';

export interface Reference {
  id: string;
  customerId: string;
  updatedAt: string;
  createdAt: string;
  firstName: string | null;
  lastName: string | null;
  relationship: string | null;
  email: string | null;
  phone: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  state: State | null;
  zip: string | null;
}

export type ReferenceSubmitBlock = Omit<Reference, 'id' | 'customerId' | 'updatedAt' | 'createdAt'>;

export interface ReferenceFormDefaultValues {
  id?: string;
  updatedAt?: string;
  createdAt?: string;
  firstName: string;
  lastName: string;
  relationship: string;
  email: string;
  phone: string;
  // Additional props
  addresses: Address[];
}

export function returnReferenceFormDefaultValues(data?: Reference): ReferenceFormDefaultValues {
  return {
    id: data?.id ?? undefined,
    updatedAt: data?.updatedAt ?? undefined,
    createdAt: data?.createdAt ?? undefined,
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    relationship: data?.relationship ?? '',
    email: data?.email ?? '',
    phone: data?.phone?.replace(/(\+1)/g, '') || '',
    addresses: [
      {
        addressLine1: data?.addressLine1 ?? '',
        addressLine2: data?.addressLine2 ?? '',
        city: data?.city ?? '',
        state: data?.state ?? '',
        zip: data?.zip ?? '',
        phone: '',
      },
    ],
  };
}

export function returnReferences(data?: any): ReferenceFormDefaultValues[] {
  return (
    data?.map((reference: any) => returnReferenceFormDefaultValues(reference)) || [
      returnReferenceFormDefaultValues(),
    ]
  );
}

export function returnReferenceSubmitBlock(data: ReferenceFormDefaultValues): ReferenceSubmitBlock {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    relationship: data.relationship,
    email: data.email || null,
    phone: convertPhoneForSubmit(data.phone),
    addressLine1: data.addresses[0].addressLine1 || null,
    addressLine2: data.addresses[0].addressLine2 || null,
    city: data.addresses[0].city || null,
    state: (data.addresses[0].state as State) || null,
    zip: data.addresses[0].zip || null,
  };
}
