import { FormProvider, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useConfig } from '../context/config';
import { fireAnalyticsEvents } from 'src/handlers/useHandleFormAnalyticsEvent';
import { AdditionalField } from 'src/widgets/AdditionalField';
import { FormActions } from './FormActions';
import './Form.css';
import toast from 'react-hot-toast';
import { useAutoSave } from 'src/helpers/useAutosave';

interface FormSubmitContextProps {
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  activeForm: string;
  formValues: Partial<any>;
  setActiveForm: (activeForm: string) => void;
  handleFormGroupSubmit: (data: any) => Promise<void>;
  handleFormGroupBack: () => void;
  handleFormGroupSkip: () => void;
  handleFormCancel: () => void;
  formSteps: string[];
  activeStepIndex: number;
  conditionalFields?: {
    [key: string]: (data: any) => boolean;
  };
  submitText?: string;
  showSkipButton?: boolean;
  additionalFields?: AdditionalField[];
  handleFormSkip?: any;
}

const FormSubmitContext = createContext<FormSubmitContextProps | undefined>(undefined);

export const useFormSubmit = () => {
  const context = useContext(FormSubmitContext);
  if (!context) {
    throw new Error('useFormSubmit must be used within a FormSubmitProvider');
  }
  return context;
};

interface FormSubmitProviderProps {
  children: React.ReactNode;
  handleFormSubmit: (data: any) => Promise<void>;
  handleFormCancel: any;
  multistepFormValues?: any;
  steps: string[];
  classNames?: string | null;
  conditionalFields?: {
    [key: string]: (data: any) => boolean;
  };
  savedForm?: boolean;
  submitText?: string;
  showSkipButton?: boolean;
  additionalFields?: AdditionalField[];
  handleFormSkip?: any; // "Next" button doesn't submit the form but performs a separate action
}

export const FormWrapper: React.FC<FormSubmitProviderProps> = ({
  children,
  classNames,
  handleFormSubmit,
  handleFormCancel,
  multistepFormValues = {},
  steps,
  conditionalFields = {},
  savedForm,
  submitText = undefined,
  showSkipButton = false,
  additionalFields = undefined,
  handleFormSkip = undefined,
}) => {
  const config = useConfig()!;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState<Partial<any>>(multistepFormValues);
  const formSteps = useMemo(() => {
    const array: string[] = steps;

    //filter steps based on conditional fields. if no conditional field prop with step exists, include it. if it does, check if it should be included
    return array.filter(step => {
      if (conditionalFields[step] === undefined) {
        return true;
      }
      return !conditionalFields[step](formValues);
    });
  }, [formValues, steps, conditionalFields]);
  const [activeStepIndex, setActiveStepIndex] = useState(savedForm ? formSteps.length - 1 : 0);

  const handleFormGroupSubmit = async (data: any) => {
    const updatedFormData = {
      ...formValues,
      ...data,
    };

    setIsSubmitting(true);
    setFormValues(updatedFormData);

    fireAnalyticsEvents(config.form || 'Space Widget', formSteps[activeStepIndex]);

    if (activeStepIndex !== formSteps.length - 1) {
      setActiveStepIndex(prev => prev + 1);
      setIsSubmitting(false);
    } else {
      try {
        await handleFormSubmit(updatedFormData).finally(() => {
          setIsSubmitting(false);
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const setActiveForm = (activeForm: string) => {
    setActiveStepIndex(formSteps.findIndex(item => item === activeForm));
  };

  const handleFormGroupBack = () => {
    if (activeStepIndex === 0) {
      handleFormCancel();
    } else {
      setActiveStepIndex(prev => prev - 1);
    }
  };

  const handleFormGroupSkip = () => {
    setActiveStepIndex(prev => prev + 1);
  };

  if (formSteps[activeStepIndex] === undefined) {
    // to do: add loading spinner
    return <div>Form Submitting</div>;
  }

  return (
    <FormSubmitContext.Provider
      value={{
        handleFormGroupSubmit,
        handleFormGroupBack,
        handleFormGroupSkip,
        setActiveForm,
        activeForm: formSteps[activeStepIndex],
        activeStepIndex,
        handleFormCancel,
        isSubmitting,
        setIsSubmitting,
        formValues,
        conditionalFields,
        formSteps,
        submitText,
        showSkipButton,
        additionalFields,
        handleFormSkip,
      }}
    >
      <div className={`form-modal-wrapper ${classNames}`}>
        {/* {navigation} */}
        <div className="multi-step-form">{children}</div>
      </div>
    </FormSubmitContext.Provider>
  );
};

//Really Form Step Wrapper
function FormContent({
  id,
  children,
  // className = '',
  autocomplete = undefined,
  onSubmit = undefined,
  data = undefined,
  schema = {},
  autosave = false,
  btnText = 'Continue',
  isPrimaryButtonSkip = false,
  isPrimaryButtonHidden = false,
}: {
  id: string;
  children: React.ReactNode;
  // className?: string;
  autocomplete?: string;
  data: any;
  schema?: any;
  autosave?: boolean;
  btnText?: string;
  isPrimaryButtonSkip?: boolean;
  isPrimaryButtonHidden?: boolean;
  onSubmit?: (data: any) => Promise<void>; // Optional step-specific submit handler
}) {
  const { handleFormGroupSubmit, formValues } = useFormSubmit();
  const props = { btnText, isPrimaryButtonSkip, isPrimaryButtonHidden };

  const form = useForm({
    resolver: joiResolver(Joi.object(schema), {
      // Allows all unused value props to pass through multi-step forms
      allowUnknown: true,
    }),
    mode: 'onBlur',
    //Optionally pass in default values from the parent form
    defaultValues: data || formValues,
  });
  useAutoSave(form, onSubmit || handleFormGroupSubmit, autosave);

  console.log(form.getValues(), form.formState.errors);

  useEffect(() => {
    if (Object.keys(form.formState.errors).length > 0) {
      const errorMessages = Object.values(form.formState.errors)
        .map(error => error!.message)
        .join(', ');

      toast.error(errorMessages);
    }
  }, [form.formState.errors]);

  // console.log(formSteps, activeStepIndex, activeForm, 'activeForm');

  //TODO: preload next form step data
  // console.log(form.getValues(), form.formState.errors);
  return (
    <FormProvider {...form}>
      <form
        id={id}
        className="form-step--form"
        // className={renderClass}
        onSubmit={form.handleSubmit(onSubmit || handleFormGroupSubmit)}
        autoComplete={autocomplete}
      >
        <div className="form-step--content">{children}</div>
        <FormActions {...props} />
      </form>
    </FormProvider>
  );
}

//Really Form Step Wrapper
export function FormContentWrapper(props) {
  const { activeForm, formSteps, activeStepIndex } = useFormSubmit();
  const isAnimated = props.isAnimated ?? true;

  const renderClass = useMemo(() => {
    const classes = ['form-step', `${isAnimated ? 'is-animated' : ''}`, props.className];
    if (activeForm === props.id) classes.push('active');
    if (activeStepIndex > formSteps.findIndex(item => item === props.id)) classes.push('left');
    if (activeStepIndex < formSteps.findIndex(item => item === props.id)) classes.push('right');
    return classes.join(' ');
  }, [activeForm, activeStepIndex, formSteps, props.id, props.className, isAnimated]);

  if (props.id !== activeForm) {
    return <div className={renderClass}></div>;
  }

  return (
    <div className={renderClass}>
      <FormContent {...props} />
    </div>
  );
}

export function useSpaceForm(resolver, defaultValues) {
  return useForm({
    resolver: joiResolver(Joi.object(resolver), {
      // Allows all unused value props to pass through multi-step forms
      allowUnknown: true,
    }),
    mode: 'onBlur',
    defaultValues,
  });
}

export function FormBody({ title, body }: { title: string | null; body: string | null }) {
  return (
    <div className="form-step--title-bar">
      {title && <div className="heading-6">{title}</div>}
      {body && <div className="text--section-title">{body}</div>}
    </div>
  );
}
