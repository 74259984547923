import React, {
  useState,
  useRef,
  forwardRef,
  useMemo,
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  ReactNode,
  HTMLProps,
  useLayoutEffect,
  isValidElement,
  cloneElement,
  ButtonHTMLAttributes,
} from 'react';
import {
  useFloating,
  flip,
  shift,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  Placement,
  FloatingPortal,
  FloatingFocusManager,
  useId,
  FloatingArrow,
  arrow,
  autoUpdate,
} from '@floating-ui/react';
import './Popover.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faPhone } from '@fortawesome/pro-regular-svg-icons';
import { Colors } from 'src/types/colors';

interface PopoverOptions {
  initialOpen?: boolean;
  placement?: Placement;
  modal?: boolean;
  open?: boolean;
  padding?: number;
  onOpenChange?: (open: boolean) => void;
}

export function usePopover({
  initialOpen = false,
  modal,
  padding = 5,
  open: controlledOpen,
  placement,
  onOpenChange: setControlledOpen,
}: PopoverOptions = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const [labelId, setLabelId] = useState<string | undefined>();
  const [descriptionId, setDescriptionId] = useState<string | undefined>();
  const arrowRef = useRef<any | null>(null);
  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement: placement || 'bottom-start',
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      flip({
        crossAxis: true, // always check for horizontal overflow
        fallbackAxisSideDirection: 'end',
        padding: padding,
      }),
      shift({ padding: padding }),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const context = data.context;

  const click = useClick(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const interactions = useInteractions([click, dismiss, role]);

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      modal,
      labelId,
      descriptionId,
      arrowRef,
      setLabelId,
      setDescriptionId,
    }),
    [open, setOpen, interactions, data, modal, arrowRef, labelId, descriptionId]
  );
}

type ContextType =
  | (ReturnType<typeof usePopover> & {
      setLabelId: Dispatch<SetStateAction<string | undefined>>;
      setDescriptionId: Dispatch<SetStateAction<string | undefined>>;
    })
  | null;

const PopoverContext = createContext<ContextType>(null);

export const usePopoverContext = () => {
  const context = useContext(PopoverContext);

  if (context == null) {
    throw new Error('Popover components must be wrapped in <Popover />');
  }

  return context;
};

export function Popover({
  children,
  modal = false,
  ...restOptions
}: {
  children?: ReactNode;
} & PopoverOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const popover = usePopover({ modal, ...restOptions });

  return <PopoverContext.Provider value={popover}>{children}</PopoverContext.Provider>;
}

interface PopoverTriggerProps {
  children?: ReactNode;
  asChild?: boolean;
}

export const PopoverTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement> & PopoverTriggerProps>(
  function PopoverTrigger({ children, asChild = false, ...props }, propRef) {
    const context = usePopoverContext();
    const childrenRef = (children as any)?.ref;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && isValidElement(children)) {
      return cloneElement(
        children,
        context.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          'data-state': context.open ? 'open' : 'closed',
        })
      );
    }

    return (
      <button
        ref={ref}
        type="button"
        // The user can style the trigger based on the state
        data-state={context.open ? 'open' : 'closed'}
        {...context.getReferenceProps(props)}
      >
        {children}
      </button>
    );
  }
);

interface PopoverContentProps extends React.HTMLProps<HTMLDivElement> {
  containerRef?: React.RefObject<HTMLDivElement> | null;
  initialFocus?: number | string; // Added property to remove autofocus for carousel
}

export const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>(
  function PopoverContent({ style, containerRef, initialFocus, ...props }, propRef) {
    const { context: floatingContext, ...context } = usePopoverContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    if (!floatingContext.open) return null;

    return (
      <FloatingPortal root={containerRef}>
        <FloatingFocusManager
          context={floatingContext}
          modal={context.modal}
          initialFocus={initialFocus === 'unset' ? 2 : undefined}
        >
          <div
            ref={ref}
            style={{ ...context.floatingStyles, ...style }}
            aria-labelledby={context.labelId}
            aria-describedby={context.descriptionId}
            {...context.getFloatingProps(props)}
          >
            {props.children}
          </div>
        </FloatingFocusManager>
      </FloatingPortal>
    );
  }
);

export const PopoverHeading = forwardRef<HTMLHeadingElement, HTMLProps<HTMLHeadingElement>>(
  function PopoverHeading(props, ref) {
    const { setLabelId } = usePopoverContext();
    const id = useId();

    // Only sets `aria-labelledby` on the Popover root element
    // if this component is mounted inside it.
    useLayoutEffect(() => {
      setLabelId(id);
      return () => setLabelId(undefined);
    }, [id, setLabelId]);

    return (
      <h2 {...props} ref={ref} id={id}>
        {props.children}
      </h2>
    );
  }
);

export const PopoverDescription = forwardRef<HTMLParagraphElement, HTMLProps<HTMLParagraphElement>>(
  function PopoverDescription(props, ref) {
    const { setDescriptionId } = usePopoverContext();
    const id = useId();

    // Only sets `aria-describedby` on the Popover root element
    // if this component is mounted inside it.
    useLayoutEffect(() => {
      setDescriptionId(id);
      return () => setDescriptionId(undefined);
    }, [id, setDescriptionId]);

    return <div {...props} ref={ref} id={id} />;
  }
);

export const PopoverClose = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>(
  function PopoverClose(props, ref) {
    const { setOpen } = usePopoverContext();
    return (
      <button
        type="button"
        ref={ref}
        {...props}
        onClick={event => {
          props.onClick?.(event);
          setOpen(false);
        }}
      />
    );
  }
);

export const PopoverArrow = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
  function PopoverArrow() {
    const { context: floatingContext, ...context } = usePopoverContext();

    return (
      <FloatingArrow
        ref={context.arrowRef}
        context={floatingContext}
        className="popover-media--arrow"
      />
    );
  }
);

interface ContextItemProps {
  icon: IconDefinition;
  text: string;
  color?: string;
}

export function ContextItem({ icon, text, color = Colors.Gray0 }: ContextItemProps) {
  return (
    <>
      <FontAwesomeIcon
        icon={icon}
        color={color}
        className="popover-context-menu--item--icon"
        transform={{ flipX: icon === faPhone ? true : undefined }} // Flip phone icon
      />
      <span style={{ color }}>{text}</span>
    </>
  );
}
