import { FormPrimaryNavigation } from './FormPrimaryNavigation';
import { FormBackAction } from './FormBackAction';
import { FormPrimaryAction } from './FormPrimaryAction';
import { FormSkipAction } from './FormSkipAction';
import './FormActions.css';

export function FormActions(props) {
  return (
    <div>
      {/* <FormSignIn /> */}
      <FormPrimaryNavigation>
        <FormBackAction />
        <FormPrimaryAction {...props} />
        <FormSkipAction />
      </FormPrimaryNavigation>
    </div>
  );
}
