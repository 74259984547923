import { useMemo } from 'react';
import { Colors } from 'src/types/colors';
import { ComponentState } from 'src/types/component';

export function useIconColor(state: ComponentState[]) {
  const iconColor = useMemo(() => {
    if (state.includes(ComponentState.Done)) {
      return Colors.White;
    }

    if (state.includes(ComponentState.Error)) {
      return Colors.Red1;
    }

    if (state.includes(ComponentState.Active)) {
      return Colors.Blue1;
    }

    if (state.includes(ComponentState.Disabled)) {
      return Colors.Gray4;
    }

    return Colors.Black;
  }, [state]);

  return iconColor;
}
