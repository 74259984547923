import Joi from 'joi';
import {
  nameRegex,
  emailRegex,
  isValidPhone,
  isValidEmail,
  isValidBirthDate,
  isValidFullSsn,
  isValidPartialSsn,
  getStateFromZip,
} from './validation';
import { DeclarationType } from './tradeinDeclaration';
import { PaymentType } from 'src/types/deal';
import { EmploymentStatus } from 'src/types/employmentStatus';
import { SearchBy, TradeinCondition, TradeInPayoffType } from 'src/types/tradein-block';
import { ApplicationMode, HomeOwnership } from 'src/types/creditapplication-block';

export const firstName = Joi.string().pattern(nameRegex).required().messages({
  'string.empty': `First name is required`,
  'string.pattern.base': `First name must contain only letters`,
});

export const middleName = Joi.string().pattern(nameRegex).allow('').messages({
  'string.pattern.base': `Middle name must contain only letters`,
});

export const lastName = Joi.string().pattern(nameRegex).required().messages({
  'string.empty': `Last name is required`,
  'string.pattern.base': `Last name must contain only letters`,
});

export const email = Joi.string().pattern(emailRegex).required().messages({
  'string.empty': `Email address is required`,
  'string.pattern.base': `Please enter a valid email address`,
});

export const phone = Joi.string()
  .custom((value, helpers) => {
    if (!isValidPhone(value.replaceAll('-', ''))) {
      return helpers.message({ custom: 'Please enter a valid phone number' });
    }
    return value;
  }, 'custom validation')
  .required()
  .messages({
    'string.empty': `Phone number is required`,
  });

export const emailOptional = Joi.custom((value, helpers) => {
  if (!isValidEmail(value) && !isValidPhone(helpers.state.ancestors[0].phone.replaceAll('-', ''))) {
    return helpers.message({ custom: 'Phone or email is required' });
  }
  if (!isValidEmail(value) && value !== '') {
    return helpers.message({ custom: 'Please enter a valid email address' });
  }
  return value;
});

export const phoneOptional = Joi.custom((value, helpers) => {
  if (!isValidPhone(value.replaceAll('-', '')) && !isValidEmail(helpers.state.ancestors[0].email)) {
    return helpers.message({ custom: 'Phone or email is required' });
  }
  if (!isValidPhone(value.replaceAll('-', '')) && value !== '') {
    return helpers.message({ custom: 'Please enter a valid phone number' });
  }
  return value;
}, 'custom validation');

export const hiddenPhone = Joi.string().allow('');

export const optoutCalls = Joi.custom((value, helpers) => {
  if (value && helpers.state.ancestors[0].optoutSms && helpers.state.ancestors[0].optoutEmails) {
    return helpers.message({ custom: 'We need at least one way to contact you' });
  }
  return value;
});

export const optoutSms = Joi.custom((value, helpers) => {
  if (value && helpers.state.ancestors[0].optoutCalls && helpers.state.ancestors[0].optoutEmails) {
    return helpers.message({ custom: 'We need at least one way to contact you' });
  }
  return value;
});

export const optoutEmails = Joi.custom((value, helpers) => {
  if (value && helpers.state.ancestors[0].optoutSms && helpers.state.ancestors[0].optoutCalls) {
    return helpers.message({ custom: 'We need at least one way to contact you' });
  }
  return value;
});

export const comments = Joi.string().allow('');

export const softPullConsent = Joi.boolean().invalid(false).required().messages({
  'string.empty': `You must consent to a credit check to continue`,
  'any.invalid': `You must consent to a credit check to continue`,
});
export const esignatureConsent = Joi.boolean().invalid(false).required().messages({
  'string.empty': `You must consent to the use of an eSignature to continue`,
  'any.invalid': `You must consent to the use of an eSignature to continue`,
});
export const esignature = Joi.string().required().messages({
  'string.empty': `Please sign your name to continue`,
});

export const location = Joi.string().required().messages({
  'string.empty': `Please select the location you are interested in`,
});

export const applicantType = Joi.string().required().messages({
  'string.empty': `Applicant Type is required`,
});

export const calendarAppointmentDate = Joi.date().required().messages({
  'string.empty': `Date is required`,
});

export const inputAppointmentDate = Joi.string().required().messages({
  'string.empty': `Date is required`,
});

export const time = Joi.string().required().messages({
  'string.empty': `Time is required`,
  'any.invalid': `Time is required`,
});

export const notes = Joi.string().allow('');

export const type = Joi.string().required().messages({
  'string.empty': `Please select the reason for your appointment`,
});

export const status = Joi.string().allow('');

export const relationship = Joi.string().pattern(nameRegex).required().messages({
  'string.empty': `Relationship is required`,
  'string.pattern.base': `Relationship must contain only letters`,
});
export const al1 = Joi.string()
  .required()
  .pattern(/^[^\s].*$/)
  .messages({
    'string.pattern.base': `Please remove any unnecessary spaces`,
    'string.empty': `Address Line 1 is required`,
  });
export const addressLine2 = Joi.string().allow('');
export const city = Joi.string()
  .required()
  .pattern(/^[^\s].*$/)
  .messages({
    'string.pattern.base': `Please remove any unnecessary spaces`,
    'string.empty': `City is required`,
  });
export const state = Joi.string().required().messages({
  'string.empty': `State is required`,
});

export const zip = Joi.string()
  .required()
  .pattern(/^\d{5}$/)
  .messages({
    'string.empty': `Zip Code is required`,
    'string.pattern.base': `Zip Code must be 5 digits`,
  });

export const zipInState = Joi.string()
  .pattern(/^\d{5}$/)
  .custom((value, helpers) => {
    if (!getStateFromZip(value)) {
      return helpers.message({ custom: 'Please enter a valid zip' });
    }
    return value;
  }, 'custom validation')
  .messages({
    'string.pattern.base': `Zip Code must be 5 digits`,
    'string.empty': `Zip is required for accurate pricing`,
  });

export const inputCode = Joi.string()
  .pattern(/^[A-Za-z0-9]{6}$/)
  .required()
  .messages({
    'string.empty': `Please enter the code we sent to your email`,
    'string.pattern.base': `Please enter the 6-character code we sent to your email`,
  });

export const birthDate = Joi.string()
  .custom((value, helpers) => {
    if (!isValidBirthDate(value)) {
      return helpers.message({
        custom: 'Please enter a valid birth date in the format MM/DD/YYYY',
      });
    }
    return value;
  }, 'custom validation')
  .required()
  .messages({
    'string.empty': `Date of Birth is required`,
    custom: `Please enter a valid birth date in the format MM/DD/YYYY`,
  });

export const ssnFull = Joi.string()
  .custom((value, helpers) => {
    if (!isValidFullSsn(value)) {
      return helpers.message({ custom: 'This number must be 9 digits long' });
    }
    return value;
  }, 'custom validation')
  .required()
  .messages({
    'string.empty': `This field is is required`,
  });

export const ssnPartial = Joi.string()
  .custom((value, helpers) => {
    if (!isValidPartialSsn(value)) {
      return helpers.message({ custom: 'Invalid number' });
    }
    return value;
  }, 'custom validation')
  .required()
  .messages({
    'string.empty': `This field is is required`,
  });

export const driversLicenseNumber = Joi.string().required().messages({
  'string.empty': `Driver's License Number is required`,
});

export const driversLicenseState = Joi.string().required().messages({
  'string.empty': `Driver's License State is required`,
});

export const appointmentVin = Joi.string().allow('', null);
export const blockId = Joi.string().allow('', null);

export const tradeinDeclaration = Joi.string().allow(DeclarationType).required().messages({
  'string.empty': `You need to select an option to continue`,
});

export const paymentType = Joi.string()
  .allow(...Object.values(PaymentType))
  .required()
  .messages({
    'string.empty': `You need to select a payment type to continue`,
  });

export const creditScore = Joi.array()
  .items(Joi.number().min(350).max(850))
  .min(1)
  .max(1)
  .required()
  .messages({
    'number.min': `Credit score should not be below 350`,
    'number.max': `Credit score should not exceed 850`,
    'number.base': `Credit score should be a number`,
    'string.base': `Credit score should be a number`,
    'string.empty': `This field is required`,
  });

export const monthlyPaymentTarget = Joi.number().required().allow(null).allow('').messages({
  'number.base': `Monthly payment target should be a number`,
  'string.empty': `This field is required`,
});

export const downPayment = Joi.number().required().messages({
  'number.base': `Down payment should be a number`,
  'string.empty': `Down payment is required`,
});

export const employmentYears = Joi.number().required().messages({
  'number.base': `Employment years should be a number`,
  'string.empty': `Employment years is required`,
});

export const employmentMonths = Joi.number().required().messages({
  'number.base': `Employment months should be a number`,
  'string.empty': `Employment months is required`,
});

export const employer = Joi.string().required().messages({
  'string.empty': `Company name is required`,
});

export const jobTitle = Joi.string().required().messages({
  'string.empty': `Job title is required`,
});

export const monthlyIncome = Joi.number().required().messages({
  'number.base': `Monthly income should be a number`,
  'string.empty': `Monthly income is required`,
});

export const employmentStatus = Joi.string().allow(EmploymentStatus).required().messages({
  'string.empty': `Employment status is required`,
});

export const vin = Joi.string().length(17).allow('').messages({
  'string.empty': `VIN is required`,
  'string.length': `VIN must be 17 characters long`,
});
export const year = Joi.number().required().messages({
  'number.base': `Year is required`,
});
export const make = Joi.string().required().messages({
  'string.empty': `Make is required`,
});
export const model = Joi.string().required().messages({
  'string.empty': `Model is required`,
});
export const ucgvehicleid = Joi.string().required().messages({
  'string.empty': `A trim selection is required`,
  'any.required': `A configuration selection is required`,
});
export const body = Joi.string().required().messages({
  'string.empty': `A configuration selection is required`,
});
export const searchBy = Joi.string().allow(SearchBy);

export const condition = Joi.string().allow(TradeinCondition).messages({
  'string.empty': `Condition is required`,
});

export const mileage = Joi.number().min(0).required().messages({
  'number.base': `Mileage must be a number`,
  'string.empty': `Mileage is required`,
});

export const color = Joi.string().required().messages({
  'string.empty': `Please select a color`,
});

export const payoffType = Joi.string().label('Payoff').allow(TradeInPayoffType).messages({
  'string.empty': `A selection is required`,
});

export const payoffAmount = Joi.number()
  .label('Remaining Balance')
  .min(1)
  .max(999_999)
  .required()
  .default(null)
  .messages({
    'string.empty': `This amount is required`,
    'number.base': `This must be a number`,
  });

export const addressVerification = Joi.string().required().messages({
  'string.empty': `Verification is required`,
});

export const homeOwnership = Joi.string().allow(HomeOwnership).required().messages({
  'string.empty': `Residential status is required`,
});

//Force month selection if Years < 2
export const homeLivingMonths = Joi.any().custom((value, helpers) => {
  const livingYears = parseInt(helpers.state.ancestors[0].homeLivingYears);
  if (value === '' && livingYears < 2) {
    return helpers.message({ custom: 'Please select a number for months' });
  }
  return value;
});

export const homeLivingYears = Joi.number().required().messages({
  'string.empty': `A year selection is required`,
  'number.base': `Please make a year selection`,
});

export const homeMonthlyPayment = Joi.number().required().messages({
  'number.base': `Monthly payment should be a number`,
  'string.empty': `Monthly Payment is required`,
  'any.required': 'Monthly Payment is required',
});
export const otherMonthlyIncome = Joi.number().required().messages({
  'number.base': `Monthly income should be a number`,
  'string.empty': `Monthly income is required`,
});
export const otherMonthlyIncomeSource = Joi.string().required().messages({
  'string.empty': `Monthly income source is required`,
});

export const otherMonthlyIncomeOptional = Joi.custom((value, helpers) => {
  if (
    (value && !helpers.state.ancestors[0].otherMonthlyIncomeSource) ||
    (!value && helpers.state.ancestors[0].otherMonthlyIncomeSource)
  ) {
    return helpers.message({
      custom: 'If you list other monthly income, you must also list the source of that income',
    });
  }
  return value;
});
export const otherMonthlyIncomeSourceOptional = Joi.custom((value, helpers) => {
  if (
    (value && !helpers.state.ancestors[0].otherMonthlyIncome) ||
    (!value && helpers.state.ancestors[0].otherMonthlyIncome)
  ) {
    return helpers.message({
      custom: 'If you list other monthly income, you must also list the source of that income',
    });
  }
  return value;
});

export const applicationMode = Joi.string().allow(ApplicationMode).required().messages({
  'string.empty': `You must select an application option`,
});
export const optionalString = Joi.string().allow('', null);

export const requiredTrue = Joi.boolean().invalid(false).required();

export const communication = {
  optoutCalls,
  optoutSms,
  optoutEmails,
};
export const requireFalse = Joi.boolean().invalid(true).required().messages({
  'string.empty': `You must consent to SMS messages to continue`,
  'boolean.': `You must consent to SMS messages to continue`,
  'any.invalid': `You must consent to SMS messages to continue`,
});

export const communicationSchema = {
  ...communication,
  firstName,
  lastName,
  email,
  phone,
};

export const optionalCommunicationSchema = {
  firstName,
  lastName,
  email: emailOptional,
  phone: phoneOptional,
  location,
  ...communication,
};

export const optionalEmailSchema = {
  firstName,
  lastName,
  email: emailOptional,
  phone,
  ...communication,
  location,
};

export const optionalPhoneSchema = {
  firstName,
  lastName,
  email,
  phone: phoneOptional,
  ...communication,
  location,
};

export const referenceSchema = {
  firstName,
  lastName,
  email,
  phone,
  relationship,
};

export const partialReferenceSchema = {
  firstName,
  lastName,
  email: emailOptional,
  phone,
  relationship,
};

export const preapprovalSchema = {
  firstName,
  lastName,
  email: emailOptional,
  phone,
  ...communication,
  zip,
  location,
};

export const paymentTypeSchema = {
  firstName,
  middleName,
  lastName,
  email,
  phone,
};

export const appointmentSchema = {
  id: blockId,
  calendarAppointmentDate,
  inputAppointmentDate,
  time,
  notes,
  type,
  status,
  vin: appointmentVin,
  location,
};

export const addressBaseSchema = {
  al1,
  addressLine2,
  city,
  state,
  zip: zipInState,
};

export const addressSchema = {
  ...addressBaseSchema,
  phone: hiddenPhone,
};

export const employerAddressSchema = {
  ...addressSchema,
  phone,
};

export const paymentSensitiveFullSchema = {
  birthDate,
  driversLicenseNumber,
  driversLicenseState,
  ssn: ssnFull,
};

export const paymentSensitivePartialSchema = {
  birthDate,
  driversLicenseNumber,
  driversLicenseState,
  ssn: ssnPartial,
};

export function returnCustomerSchema(
  requirePhone: boolean,
  requireEmail: boolean,
  requireSms: boolean
) {
  let baseSchema = {
    firstName: firstName,
    lastName: lastName,
    email: requireEmail ? email : emailOptional,
    phone: requirePhone ? phone : phoneOptional,
    optoutCalls: optoutCalls,
    optoutSms: requireSms ? requireFalse : optoutSms,
    optoutEmails: optoutEmails,
  };

  return baseSchema;
}
