import { useCallback, useMemo } from 'react';
import { ApiError, useApiFetch } from './useApiFetch';
import { validate as uuidValidate } from 'uuid';
import useSWRMutation from 'swr/mutation';
import useSWR from 'swr';
import { PaymentType, SingleDealResponse, UnauthorizedDealResponse } from 'src/types/deal';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { useAuth } from 'src/components/context/AuthProvider';
import { useSearchApi } from './useSearchApi';
import { useConfig } from 'src/components/context/config';

// Deal Logic
// When authorized, a full deal is:
// - SingleDealResponse + WebsiteVehicle

// When unauthorized, a deal is:
// InitialVehicle = that vehicle's vin fetch data
// No initial vehicle = whatver info we have left for the deal

export function useSiteDeal(activeDealId: string) {
  const { token } = useAuth();
  const config = useConfig()!;
  const apiFetcher = useApiFetch();
  const isValidId = uuidValidate(activeDealId);
  const key = `deals/${activeDealId}`;
  const { sessionDesk } = useSessionContext();
  // TODO this needs reconfiguring
  const useAuthorizedDealFetch = token;

  const upsertDeal = useCallback(
    (url, { arg }) =>
      apiFetcher(url, {
        method: 'PUT',
        body: JSON.stringify(arg),
      }),
    [apiFetcher]
  );

  const { trigger: dealUpsert, isMutating } = useSWRMutation(key, upsertDeal);
  const { data, error, isLoading, mutate } = useSWR<SingleDealResponse, ApiError>(
    isValidId ? key : null,
    apiFetcher
  );

  // 1. If active deal is the one passed to the widget config, don't search.
  // 2. If vin is undefined or null, don't search either.
  const { searchVehicle, searchVehicleIsLoading, searchVehicleIsError } =
    useSearchApi(activeDealId);

  let siteDeal = {
    vin: null,
    paymentType:
      // Use Financing if its not disabled, leasing if its not disabled, otherwise cash
      config.paymentOptionsConfig?.disableFinancing
        ? config.paymentOptionsConfig?.disableLeasing
          ? PaymentType.Cash
          : PaymentType.Leasing
        : PaymentType.Financing,
    isDeskingLocked: sessionDesk && searchVehicle && sessionDesk[searchVehicle.vin] ? true : false,
  };

  const dealIsLoading = useMemo(() => {
    if (activeDealId === null) {
      return true;
    }

    // If authorized, look at the dealId. If it's new we have nothing to fetch, otherwise isLoading tells us progress
    if (useAuthorizedDealFetch) {
      return isLoading;
    }

    // Return progress of the search vehicle fetch
    return searchVehicleIsLoading;
  }, [activeDealId, isLoading, searchVehicleIsLoading, useAuthorizedDealFetch]);

  return {
    deal: useAuthorizedDealFetch
      ? data || siteDeal
      : ({ ...siteDeal, ...searchVehicle } as UnauthorizedDealResponse),
    dealIsLoading: dealIsLoading,
    dealIsError: token ? error : searchVehicleIsError,
    dealMutate: mutate,
    dealUpsert,
    dealIsMutating: isMutating,
  };
}
