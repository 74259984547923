import { useAuth } from 'src/components/context/AuthProvider';
import { Customer, returnCustomerDefaultValues } from 'src/types/customer';
import { ApiError, useApiFetch } from './useApiFetch';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { useCallback } from 'react';
import useSWR from 'swr';
export function useCustomer() {
  const { token, signout } = useAuth();
  const apiFetcher = useApiFetch();
  const key = 'me';
  const unauthorizedKey = 'unauthorized/general-lead';

  const { sessionCustomer } = useSessionContext();

  const updateMe = useCallback(
    async (data: any) => {
      const url = token ? key : unauthorizedKey;

      const arg = data;
      return apiFetcher(url, {
        method: 'PUT',
        body: JSON.stringify(arg),
      }).then((resp: any) => {
        return resp;
      });
    },
    [apiFetcher, token]
  );

  const { data, isLoading, error } = useSWR<Customer, ApiError>(token ? key : null, apiFetcher);

  if (error && [401, 403].includes(error.status)) {
    signout();
  }

  if (!token) {
    return {
      me: returnCustomerDefaultValues(sessionCustomer) as Customer, // To do: fix this
      meIsLoading: false,
      meIsError: undefined,
      meUpdate: updateMe,
    };
  }

  return {
    me: returnCustomerDefaultValues(data) as Customer,
    meIsLoading: isLoading,
    meIsError: error,
    meUpdate: updateMe,
  };
}
