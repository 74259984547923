import React, { createContext, ReactNode, useContext } from 'react';

import { BlockStatus } from 'src/types/blocks';
import { useTradeInStatus } from './status/tradeIn';
import { useCreditApplicationsStatus } from './status/creditApplication';
import { useDocumentStatus } from './status/useDocumentStatus';
import { useAppointmentsStatus } from './status/useAppointmentsStatus';
import { useAccountStatus } from './status/useAccountStatus';
import { useReferencesStatus } from './status/useReferencesStatus';
import { useUploadSteps } from 'src/helpers/useUploadSteps';
import { useFinanceStatus } from './status/finance';

interface NavigationContextProps {
  tradeInsStatus: BlockStatus;
  appointmentsStatus: BlockStatus;
  accountStatus: BlockStatus;
  creditApplicationsStatus: BlockStatus;
  documentsStatus: BlockStatus;
  referencesStatus: BlockStatus;
  financeStatus: BlockStatus;
}

interface NavigationProviderProps {
  children: ReactNode;
}
const StatusContext = createContext<NavigationContextProps | undefined>(undefined);

export const NavigationProvider: React.FC<NavigationProviderProps> = ({ children }) => {
  // Get the upload steps from the context
  const { blockOrder: uploadBlockOrder } = useUploadSteps();

  const financeStatus = useFinanceStatus();
  const tradeInsStatus = useTradeInStatus();
  const creditApplicationsStatus = useCreditApplicationsStatus();
  const referencesStatus = useReferencesStatus();

  const documentsStatus = useDocumentStatus(uploadBlockOrder);
  const appointmentsStatus = useAppointmentsStatus();
  const accountStatus = useAccountStatus();

  return (
    <StatusContext.Provider
      value={{
        tradeInsStatus,
        appointmentsStatus,
        accountStatus,
        creditApplicationsStatus,
        documentsStatus,
        referencesStatus,
        financeStatus,
      }}
    >
      {children}
    </StatusContext.Provider>
  );
};

export const useStatus = () => {
  const context = useContext(StatusContext);
  if (!context) {
    throw new Error('useStatus must be used within a StatusProvider');
  }
  return context;
};
