import { useFormSubmit } from './Form';
import Button from './Button';

export function FormSkip() {
  const { isSubmitting: isFormSubmitting, handleFormSkip, submitText } = useFormSubmit();

  return (
    <Button
      type="button"
      text={submitText}
      isSubmitting={isFormSubmitting}
      baseClass="btn mod-primary"
      handleSubmit={handleFormSkip}
    />
  );
}
