export enum DocumentTag {
  DriversLicenseFront = 'dlfront',
  DriversLicenseBack = 'dlback',
  Insurance = 'insurance',
  Income = 'income',
  Residence = 'residence',
  TradeIn = 'tradein',
  Reference = 'reference',
  Other = 'other',
}

export function returnDocumentTag(tag: DocumentTag) {
  switch (tag) {
    case DocumentTag.DriversLicenseFront:
      return 'Drivers License (front)';
    case DocumentTag.DriversLicenseBack:
      return 'Drivers License (back)';
    case DocumentTag.Insurance:
      return 'Insurance';
    case DocumentTag.Income:
      return 'Proof of Income';
    case DocumentTag.Residence:
      return 'Proof of Residence';
    case DocumentTag.TradeIn:
      return 'Trade In';
    case DocumentTag.Reference:
      return 'Reference';
    case DocumentTag.Other:
      return 'Other Document';
    default:
      return 'Reference';
  }
}

export function returnDocumentSubtitle(tag: DocumentTag) {
  switch (tag) {
    case DocumentTag.DriversLicenseFront:
      return 'Drivers License (front)';
    case DocumentTag.DriversLicenseBack:
      return 'Drivers License (back)';
    case DocumentTag.Insurance:
      return 'Insurance';
    case DocumentTag.Income:
      return 'Proof of Income';
    case DocumentTag.Residence:
      return 'Proof of Residence';
    case DocumentTag.TradeIn:
      return 'Trade In';
    case DocumentTag.Reference:
      return 'Reference';
    case DocumentTag.Other:
      return 'Other Document';
    default:
      return 'Reference';
  }
}

export interface DocumentBlock {
  id: string;
  customerId: string;
  updatedAt: string;
  createdAt: string;
  tag: DocumentTag;
  url: string;
  name: string;
}
