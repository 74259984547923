import './LoginHeader.css';
import { useConfig } from './context/config';

interface LoginHeaderProps {
  handleLogout?: () => void;
  dealershipLogoUrl: string | null;
}

export function LoginHeader({ dealershipLogoUrl }: LoginHeaderProps) {
  const config = useConfig()!;
  return (
    <header className="login-header">
      <nav>
        <a href={`https://${config.websiteDomain}`} className="login-header--logo">
          <img
            className="login-header--logo--img"
            src={dealershipLogoUrl ?? undefined}
            alt="Header Logo"
          />
        </a>
      </nav>
    </header>
  );
}
