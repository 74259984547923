/* eslint-disable react-hooks/exhaustive-deps */
import { CodeInput } from '../controls/CodeInput';
import { Input } from '../controls/Input';
import Button from '../form/Button';
import toast from 'react-hot-toast';
import { FormBody, FormContentWrapper, useFormSubmit } from '../form/Form';
import { AuthService } from 'src/services/Auth';
import { useAuth } from '../context/AuthProvider';
import { email, inputCode, requiredTrue } from 'src/constants/schemas';
import { useEffect, useRef, useState } from 'react';
import { FormWarning } from '../form/FormWarning';
import { Popover, PopoverTrigger } from '../floating/popover/Popover';
import { Tooltip } from '../floating/popover/Tooltip';
import { useConfig } from '../context/config';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

export const CodeForm = () => {
  return <CodeGroup />;
};

export const CodeGroup = () => {
  const {
    handleFormGroupSubmit,
    formValues,
    isSubmitting: isFormSubmitting,
    setIsSubmitting,
  } = useFormSubmit();
  const config = useConfig()!;
  const { signin } = useAuth();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [countdown, setCountdown] = useState(formValues.codeSent ? 20 : 0);
  // Need a local submission state tracker because the form submission is async
  const [displayEmail, setDisplayEmail] = useState(formValues.email);
  const initialEmail = formValues.email;

  const onSubmit = async (data: any) => {
    if (isFormSubmitting) return;

    setIsSubmitting(true);
    try {
      await AuthService.verifyCode(data.code);
      const user = await AuthService.getToken();

      signin(user, () => {
        handleFormGroupSubmit(data);
      });
    } catch (e: any) {
      setIsSubmitting(false);
      toast.error(e.message);
    }
  };

  // const form = useSpaceForm(
  //   { code: inputCode, email, codeSent: requiredTrue },
  // {
  //   code: formValues.code,
  //   email: formValues.email,
  //   codeSent: formValues.codeSent,
  // }
  // );
  const form = useForm({
    resolver: joiResolver(Joi.object({ code: inputCode, email, codeSent: requiredTrue }), {
      // Allows Additional Field inputs to exists
      allowUnknown: true,
    }),
    mode: 'onBlur',
    defaultValues: {
      code: formValues.code,
      email: formValues.email,
      codeSent: formValues.codeSent,
    },
    values: {
      code: formValues.code,
      email: formValues.email,
      codeSent: formValues.codeSent,
    },
  });
  const code = form.watch('code');
  const codeSent = form.watch('codeSent');
  const formEmail = form.watch('email');

  const handleNewCode = async () => {
    setCountdown(20);
    setIsSubmitting(true);
    await AuthService.requestCode(config.dealershipId, formEmail);
    toast.success('A new sign in code was sent to your email!');
    form.setValue('codeSent', true);
    setDisplayEmail(formEmail);
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (countdown === 0) return;

    const intervalId = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [countdown]);

  useEffect(() => {
    let timer;

    if (!codeSent && initialEmail) {
      timer = setTimeout(() => {
        setIsOpen(true);
      }, 3000);
    } else {
      setIsOpen(false);
    }

    // Cleanup function to cancel the timer if the error is resolved before the timeout
    return () => clearTimeout(timer);
  }, [codeSent]);

  useEffect(() => {
    // when code is 6 characters long, submit the form automatically
    if (code.length === 6) {
      if (codeSent) {
        onSubmit(form.getValues());
      } else {
        toast.error('Please send a code to your email first.');
      }
    }
    // /* eslint-disable react-hooks/exhaustive-deps */
  }, [code]);

  const buttonText = () => {
    if (codeSent) {
      if (countdown > 0) {
        return `Resend Code Available in ${countdown}s`;
      } else {
        return 'Resend Code';
      }
    } else {
      // if (isResendDisabled) {
      //   return `Sending...`;
      // } else {
      return 'Send My Code';
      // }
    }
  };

  return (
    <FormContentWrapper form={form} id="loginCodeForm" onSubmit={form.handleSubmit(onSubmit)}>
      <FormBody
        title="We need to verify your identity"
        body={
          codeSent
            ? `To continue check your ${displayEmail} inbox for an email containing your temporary
          sign in code. Copy and paste it here to submit.`
            : 'Click “Send Me A Code” and we will send a code to your email. Enter it here to get a head start on buying a car.'
        }
      />
      <div className="code-input--holder u-mar-top-8" ref={containerRef}>
        <div className="u-mar-top-8">
          <Input
            name="email"
            label={form.getValues('email') !== '' ? 'Email' : 'Verify Email'}
            placeholder="e.g. john.doe@example.com"
          />

          {initialEmail !== '' && initialEmail !== formEmail && (
            <div className="u-mar-top-8">
              <FormWarning text="Are you sure you want to change your email? Some information attached to this email may be lost" />
            </div>
          )}
        </div>
        <CodeInput name="code" />
        <div className="u-position-relative">
          <Button
            type="button"
            text={buttonText()}
            baseClass={codeSent ? 'btn mod-tertiary mod-full' : 'btn mod-primary mod-full'}
            isSubmitting={isFormSubmitting || countdown > 0}
            // progress={!isResendDisabled}
            handleSubmit={handleNewCode}
          />
          {/* Div for tooltip spacing since padding prop isn't working in this context */}
          <div className="u-mar-top-8"></div>
          {isOpen && (
            <Popover initialOpen={isOpen} placement="bottom">
              <PopoverTrigger className="popover-tooltip--hidden-trigger"></PopoverTrigger>
              <Tooltip
                text="Click to get your login code!"
                containerRef={containerRef}
                textClassName="popover-tooltip--text mod-medium"
              />
            </Popover>
          )}
        </div>
      </div>
    </FormContentWrapper>
  );
};
