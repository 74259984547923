import { ButtonHTMLAttributes, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Colors } from 'src/types/colors';
import './IconButton.css';

interface ButtonProps {
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  isSubmitting?: boolean;
  isDisabled?: boolean;
  className?: string;
  iconName?: any;
  iconClass?: string;
  color?: Colors;
  outline?: boolean;
  size?: 'sm' | 'md' | 'lg';
  handleSubmit: (event: any) => void;
}

export function IconButton({
  type = 'button',
  isDisabled = false,
  isSubmitting = false,
  className = '',
  color = Colors.Gray2,
  size = 'lg',
  iconName,
  iconClass,
  handleSubmit,
}: ButtonProps) {
  const onHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    handleSubmit(event);
  };

  const renderClass = useMemo(() => {
    const classes = [className, 'icon-btn'];
    classes.push(`mod-${size}`);
    if (isDisabled) classes.push('is-disabled');
    if (isSubmitting) classes.push('is-submitting');
    return classes.join(' ');
  }, [className, isDisabled, size, isSubmitting]);

  return (
    <button
      className={renderClass}
      type={type}
      onClick={type === 'submit' ? undefined : onHandleClick}
      disabled={isDisabled || isSubmitting}
    >
      <FontAwesomeIcon icon={iconName} className={iconClass} color={color} />
    </button>
  );
}
