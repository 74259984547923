import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { AuthService } from '../../services/Auth';
import { Navigate, useLocation } from 'react-router-dom';
import { useConfig } from './config';
import { FullBlockRoute } from 'src/types/blocks';

const AuthContext = createContext<any>(null);

interface AuthProviderProps {
  children: React.ReactNode;
  allowUnauthorized?: boolean;
}

export function AuthProvider({ children, allowUnauthorized }: AuthProviderProps) {
  const config = useConfig()!;
  const [token, setToken] = useState<string | null | undefined>(undefined);
  const location = useLocation();

  useEffect(() => {
    AuthService.init(config.websiteDomain, config.hostDomain);
  }, [config.websiteDomain, config.hostDomain]);

  const signin = useCallback((newToken, callback) => {
    setToken(newToken);
    callback();
  }, []);

  const signout = useCallback(async (callback?: () => any) => {
    AuthService.signOut().then(() => {
      setToken(null);
      localStorage.removeItem('activeDealId');
      if (callback) {
        callback();
      }
    });
  }, []);

  const refreshToken = useCallback(async () => {
    try {
      const newToken = await AuthService.getToken();
      setToken(newToken);
    } catch (error) {
      console.warn('Error refreshing token:', error);
      setToken(null);
    }
  }, []);

  useEffect(() => {
    const initializeToken = async () => {
      await refreshToken();
    };
    initializeToken();
  }, [refreshToken]);

  const value = { token, refreshToken, signin, signout };

  if (token === undefined) {
    return null;
  }

  if (token === null && !allowUnauthorized) {
    <Navigate to={FullBlockRoute.Login} state={{ from: location }} replace />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const auth = useContext(AuthContext);
  return auth;
}
