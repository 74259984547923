import { useMemo } from 'react';
import { useMenu } from '../context/MenuProvider';
import Menu from '../Menu';
import { Banner } from '../Banner';
import { Outlet, useLocation } from 'react-router-dom';
import { LoginHeader } from '../LoginHeader';

export function Page({ handleLogout, dealershipLogoUrl }) {
  const { isMenuOpen, isVehicleSelectorOpen } = useMenu();
  const location = useLocation();

  const renderClass = useMemo(() => {
    const classes = ['page'];
    if (isMenuOpen || isVehicleSelectorOpen) classes.push('is-overlay-open');
    if (isMenuOpen) classes.push('is-menu-open');
    return classes.join(' ');
  }, [isMenuOpen, isVehicleSelectorOpen]);

  if (location.pathname === '/login' || location.pathname === '/login/') {
    return (
      <div className="login-page">
        <LoginHeader dealershipLogoUrl={dealershipLogoUrl} />
        <div className="main">
          <Outlet />
        </div>
      </div>
    );
  }

  return (
    <div className={renderClass}>
      <Menu dealershipLogoUrl={dealershipLogoUrl} handleLogout={handleLogout} />
      <div className="page--right">
        <Banner />
        <main className="main">
          <Outlet />
        </main>
      </div>
    </div>
  );
}
