import { ButtonHTMLAttributes, useMemo } from 'react';

import { LottieLoader } from '../LottieLoader';
import { Colors } from 'src/types/colors';
import './Button.css';

interface ButtonProps {
  type: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  text?: string;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  baseClass: string;
  handleSubmit: (event: any) => void;
}

export default function Button({
  type,
  text,
  isSubmitting,
  isDisabled,
  baseClass,
  handleSubmit,
}: ButtonProps) {
  const onHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmit(event);
  };

  const renderClass = useMemo(() => {
    const classes = [baseClass];
    if (isSubmitting) classes.push('is-submitting');
    if (isDisabled) classes.push('is-disabled');
    return classes.join(' ');
  }, [isSubmitting, isDisabled, baseClass]);

  const loaderColor = useMemo(
    () =>
      baseClass.includes('mod-text-icon') || baseClass.includes('mod-standard')
        ? Colors.Black
        : Colors.White,
    [baseClass]
  );

  return (
    <button
      className={renderClass}
      type={type}
      onClick={type === 'submit' ? undefined : onHandleClick}
      disabled={isDisabled || isSubmitting}
    >
      {isSubmitting ? <LottieLoader color={loaderColor} height={28} width={24} /> : text}
    </button>
  );
}
