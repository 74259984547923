import { Colors } from 'src/types/colors';
import './Label.css';

interface LabelProps {
  text: string;
  color: Colors;
  className?: string;
}

export function Label({ text, color, className }: LabelProps) {
  function getLabelType(color: Colors) {
    switch (color) {
      case Colors.Blue1:
        return 'mod-blue-1';
      case Colors.Blue2:
        return 'mod-basic';
      case Colors.Black:
        return 'mod-black';
      case Colors.Green1:
        return 'mod-success';
      case Colors.Orange1:
        return 'mod-orange';
      case Colors.Red1:
        return 'mod-error';
      case Colors.Gray6:
        return 'mod-grey';
      case Colors.White:
        return 'mod-white';
      default:
        return 'mod-basic';
    }
  }

  return <div className={`label ${getLabelType(color)} ${className}`}>{text}</div>;
}
