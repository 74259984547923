import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { useFormSubmit } from './Form';
import { FormSkip } from './FormSkip';
import Button from './Button';
import { InfoCommunicationGroupId } from '../steps/InfoCommunicationGroup';
import { FullBlockRoute } from 'src/types/blocks';

export function FormPrimaryAction(props) {
  const {
    isSubmitting: isFormSubmitting,
    activeForm,
    activeStepIndex,
    formSteps,
    formValues,
    submitText,
    handleFormSkip,
    handleFormGroupSkip,
  } = useFormSubmit();
  const isLoginPage = useMatch(FullBlockRoute.Login);
  const isFinalStep = formSteps?.length - 1 === activeStepIndex;

  const text = useMemo(() => {
    //TODO: get right button text
    if (props.btnText) return props.btnText;
    if (submitText) return submitText;
    if (activeForm === InfoCommunicationGroupId) return 'Agree & Continue';
    if (isFinalStep) return 'Submit';
    return 'Continue';
  }, [submitText, isFinalStep, activeForm, props]);

  console.log(props.btnText, submitText, activeForm, isFinalStep);
  const hidePrimaryButton = useMemo(() => {
    if (props.isPrimaryButtonHidden) {
      return true;
    }
    if (isLoginPage) {
      //TODO: test
      return activeForm === 'loginCodeForm' && formValues.email.length && !formValues.codeSent;
    }
    return false;
  }, [isLoginPage, activeForm, formValues, props.isPrimaryButtonHidden]);

  if (hidePrimaryButton) {
    return null;
  }

  if (handleFormSkip) {
    return <FormSkip />;
  }
  return (
    <Button
      isSubmitting={isFormSubmitting}
      baseClass="btn mod-primary mod-tall"
      type={props.isPrimaryButtonSkip ? 'button' : 'submit'}
      text={text}
      handleSubmit={() => {
        if (!props.isPrimaryButtonSkip) return;
        handleFormGroupSkip();
      }}
    />
  );
}
