import { useEffect, useMemo } from 'react';
import { ConfigContext, useConfigState } from 'src/components/context/config';
import { AuthProvider } from 'src/components/context/AuthProvider';
import { Routes, Route, Outlet } from 'react-router-dom';
import { RequireAuth } from 'src/components/RequireAuth';
import Custom404 from './404';
import Toast from 'src/components/Toast';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { LoginForm } from 'src/components/authentication/LoginForm';
import { WEB_ANALYTICS_SDK_URL } from 'src/constants/env';
//import { OfferMatrix } from './deal/payment-method/offer';
import { Layout2 } from 'src/components/layout/Layout';
library.add(faTimes);

// It's tree-shaked if added via esm (TODO: "sideEffects" - https://webpack.js.org/guides/tree-shaking/#mark-the-file-as-side-effect-free)
require('@fortawesome/fontawesome-svg-core/styles.css');
require('../styles/variables.css');
require('../styles/global.css');

const LOCALHOST_HOSTNAME = 'localhost';
// stage 4 currently - can be used safely in the ES version
// eslint-disable-next-line no-undef
const hostDomain = globalThis?.location?.hostname ?? LOCALHOST_HOSTNAME;

export default function App() {
  const config = useConfigState();

  const dealershipLogoUrl = useMemo(
    () =>
      hostDomain !== LOCALHOST_HOSTNAME
        ? config?.websiteDomain
          ? `https://${config.websiteDomain}/wp-json/space-multisite/v1/api/?logo=true`
          : null
        : '/logo.png',
    [config?.websiteDomain]
  );

  const AuthenticatedLayout = () => (
    <RequireAuth>
      <Layout2 dealershipLogoUrl={dealershipLogoUrl} />
    </RequireAuth>
  );

  const UnauthenticatedLayout = () => <Layout2 dealershipLogoUrl={dealershipLogoUrl} />;

  useEffect(() => {
    if (config?.websiteDomain && hostDomain !== LOCALHOST_HOSTNAME) {
      const favIconEl = document.getElementById('favicon') as HTMLLinkElement;
      favIconEl.href = `https://${config.websiteDomain}/wp-json/space-multisite/v1/api/?favicon=true`;
    }
  }, [config?.websiteDomain]);

  useEffect(() => {
    const webAnalyticsScriptId = 'web-analytics-sdk';
    const existingScript = document.getElementById(webAnalyticsScriptId);
    if (existingScript || hostDomain === LOCALHOST_HOSTNAME) {
      return;
    }

    const script = document.createElement('script');
    script.id = webAnalyticsScriptId;
    script.async = true;
    script.src = WEB_ANALYTICS_SDK_URL!;

    document.body.appendChild(script);
  }, []);

  if (!config) {
    return null;
  }

  return (
    <>
      <ConfigContext.Provider value={config}>
        <Toast />
        <AuthProvider allowUnauthorized={false}>
          <Routes>
            <Route element={<UnauthenticatedLayout />}>
              {['sign-in', 'login'].map((path, index) => (
                <Route key={index} path={path} element={<LoginForm />} />
              ))}
            </Route>
            <Route path="deals" element={<AuthenticatedLayout />}>
              {/* <Route path="/" element={<NavigateNextDealBlock />} /> */}
              <Route path=":dealId" element={<Outlet />}></Route>
            </Route>
            <Route element={<AuthenticatedLayout />}></Route>
            <Route path="*" element={<Custom404 />} />
          </Routes>
        </AuthProvider>
      </ConfigContext.Provider>
    </>
  );
}
