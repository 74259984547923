import React, { createContext, useContext } from 'react';
import { ComponentState } from 'src/types/component';

interface ComponentStateContextProps {
  state: ComponentState[];
}

const ComponentStateContext = createContext<ComponentStateContextProps>({ state: [] });

export const useComponentState = () => {
  const context = useContext(ComponentStateContext);
  if (!context) {
    throw new Error('useComponenetState must be used within a Component state provider');
  }
  return context;
};

interface ComponentStateProviderProps {
  state: ComponentState[];
  children: React.ReactNode;
}

export const ComponentStateProvider: React.FC<{
  children: React.ReactNode;
  state: ComponentState[];
}> = ({ state, children }: ComponentStateProviderProps) => {
  return (
    <ComponentStateContext.Provider value={{ state }}>{children}</ComponentStateContext.Provider>
  );
};
