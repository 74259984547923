import { Input } from '../controls/Input';

export function FirstName() {
  return <Input name="firstName" label="First Name" placeholder="e.g. John" />;
}

export function MiddleName() {
  return <Input name="middleName" label="Middle Name" placeholder="e.g. Michael" />;
}

export function LastName() {
  return <Input name="lastName" label="Last Name" placeholder="e.g. Doe" />;
}

export function Email() {
  return <Input name="email" label="Email" placeholder="e.g. john.doe@example.com" />;
}

export function Phone() {
  return <Input name="phone" type="phone" label="Phone" placeholder="e.g. 555-123-4567" />;
}

export function SSN({ label }: { label: string }) {
  return <Input name="ssn" label={label} placeholder="e.g. 123-45-6789" />;
}

export function DLNumber() {
  return <Input name="driversLicenseNumber" label="DL Number" placeholder="e.g. D1234567" />;
}

export function Notes() {
  return <Input name="notes" label="Note" placeholder="Additional information (optional)" />;
}

export function AddressLine2() {
  return <Input name="addressLine2" label="Address Line 2" placeholder="e.g. Apt 4B" />;
}

export function City() {
  return <Input name="city" label="City" placeholder="e.g. Los Angeles" />;
}

export function Employer() {
  return <Input name="employer" label="Company Name" placeholder="e.g. ACME Corp" />;
}

export function EmployerPhone() {
  return <Input name="phone" label="Employer Phone" type="phone" placeholder="e.g. 555-123-4567" />;
}

export function JobTitle() {
  return <Input name="jobTitle" label="Job Title" placeholder="e.g. Software Engineer" />;
}

export function OtherMonthlyIncomeSource() {
  return (
    <Input
      name="otherMonthlyIncomeSource"
      label="Other Monthly Income Source"
      placeholder="e.g. Freelance Work"
      validateOnSubmit={true}
    />
  );
}

export function Esignature() {
  return <Input name="esignature" label="eSignature" placeholder="Type your name here" />;
}

export function Mileage() {
  return <Input name="mileage" label="Mileage" placeholder="e.g. 45000" type="integer" />;
}

export function LoanLender() {
  return <Input name="payoffLender" label="Lender" placeholder="e.g. Bank of America" />;
}

export function Relationship() {
  return <Input name="relationship" label="Relationship" placeholder="e.g. Landlord" type="text" />;
}

export function MonthlyPaymentTarget() {
  return (
    <Input name="monthlyPaymentTarget" label="Monthly Payment" placeholder="$0" type="integer" />
  );
}
