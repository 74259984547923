import { useEffect } from 'react';
import { EmailForm } from '../steps/EmailGroup';
import { CodeForm } from '../steps/CodeGroup';
import toast from 'react-hot-toast';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { FormWrapper } from '../form/Form';
import { useAuth } from '../context/AuthProvider';
import { useCustomer } from 'src/fetches/useCustomer';

export enum LoginState {
  Verify = 'verify',
}

const StepMap = {
  Email: 'loginEmailForm',
  Code: 'loginCodeForm',
};

export function LoginForm() {
  const { token } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { me } = useCustomer();
  const paramEmail = searchParams.get('email');
  const location = useLocation();
  const navigate = useNavigate();
  // useDocTitle(`${config.dealershipName} | Easy Sign In`);
  const initialEmail = me?.email || paramEmail || '';

  const handleFormSubmit = async () => {
    try {
      const returnUrl = location.state?.from?.pathname || '/';
      const addParams = location.state?.from?.search;
      navigate(addParams ? `${returnUrl}${addParams}` : returnUrl, { replace: true });
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    const message = searchParams.get('message');

    if (message === 'loggedOut') {
      toast.success('You were successfully logged out');
    }
    if (message === 'needsAuth') {
      toast.success('Please log in again');
    }
    if (message) {
      searchParams.delete('message');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (token) {
      navigate('/');
    }
  }, [token, navigate]);

  if (token) {
    return null;
  }

  return (
    <FormWrapper
      handleFormSubmit={handleFormSubmit}
      handleFormCancel={() => {
        //navigate to previous page in history stack
        navigate(-1);
      }}
      multistepFormValues={{
        email: initialEmail,
        code: '',
        codeSent: false,
      }}
      steps={
        initialEmail !== '' && location.state !== LoginState.Verify
          ? [StepMap.Code]
          : [StepMap.Email, StepMap.Code]
      }
    >
      <EmailForm />
      <CodeForm />
    </FormWrapper>
  );
}
