import { FormContentWrapper } from '../form/Form';
import { Email, FirstName, LastName, Phone } from '../inputs/Input';
import { Communication } from '../controls/Communication';
import { formatGeneralLeadBlock } from 'src/types/lead';
import { useSessionContext } from '../context/SessionProvider';
import { useApiFetch } from 'src/fetches/useApiFetch';
import { useFormProps } from '../context/FormPropsProvider';
import { returnCustomerSchema } from '../../constants/schemas';
import { FormBody, useFormSubmit } from '../form/Form';
import { useConfig } from '../context/config';

// In the payments flow and credit app, we are posting a lead when the user submits this form.

export const InfoCommunicationGroupId = 'infoCommunicationGroupForm';
export const InfoCommunicationGroup = ({ isSubmit = false }: { isSubmit?: boolean }) => {
  const config = useConfig()!;
  const { props: formProps } = useFormProps();
  const apiFetch = useApiFetch();
  const { handleFormGroupSubmit, formValues } = useFormSubmit();
  const { setSessionCustomer, setSessionLocation, setSessionZip, setLocalDealerId } =
    useSessionContext();
  //TODO: these were passed as props for a temp fix
  const requirePhone = config.forms?.lead?.requirePhone ?? true;
  const requireEmail = true;
  // If we are in retailing, do NOT require phone number

  const schema = returnCustomerSchema(
    requirePhone,
    requireEmail,
    config.forms?.lead?.requireSms ?? false
  );

  async function postLead(data: any) {
    const url = 'unauthorized/general-lead';

    let arg = {
      blocks: [formatGeneralLeadBlock(data)],
      vin: data.vin?.length ? data.vin : null,
      ...(data.dealerId && { dealerId: data.dealerId }),
      // TODO: does zip ever go here?
      // TODO: location goes where exactly in this form?
      // location: returnPayloadLocation(data.location, locationIsUndetermined),
    };
    const res = await apiFetch(url, {
      method: 'POST',
      body: JSON.stringify(arg),
    }).then(() => {
      if ('blocks' in arg) {
        const { comments, ...blockWithoutComments } = arg.blocks[0];
        setSessionCustomer(blockWithoutComments);
      }

      if ('dealerId' in arg && arg.dealerId) {
        setLocalDealerId(arg.dealerId);
      }

      if ('zip' in arg) {
        setSessionZip(arg.zip);
      }

      if ('location' in arg) {
        setSessionLocation(arg.location);
      }
    });

    // TODO: ask kyle if we need to send a host message about submission success at this point
    // config.hostMessage!({
    //   type: WidgetHostMessageType.Submit,
    //   payload: {
    //     email: arg.customer.email,
    //     dealId: resp.dealId,
    //     form: props.form,
    //     customerId: resp.customerId,
    //   },
    // });

    return res;
  }
  // const optoutSms = form.watch('optoutSms');

  // useEffect(() => {
  //   if (isSubmitting || !config?.forms?.lead?.requireSms || config.isRetailing) {
  //     return;
  //   }
  //   if (activeForm === 'infoCommunicationForm' && !form.formState.isValid) {
  //     setIsSubmitting(true);
  //   } else if (!isSubmitting) {
  //     setIsSubmitting(false);
  //   }
  // }, [config, activeForm, setIsSubmitting, optoutSms, isSubmitting, form.formState]);

  const onSubmit = async (data: any) => {
    if (isSubmit) {
      let submitData = { ...formValues, ...data };
      try {
        await postLead(submitData);
      } catch (e) {
        console.log(e);
      }
    }

    handleFormGroupSubmit(data);
  };

  return (
    //TODO: get correct schema
    <FormContentWrapper
      id={InfoCommunicationGroupId}
      onSubmit={onSubmit}
      schema={schema}
      data={formValues}
      btnText="Agree & Continue"
    >
      <FormBody
        // TODO: this text is different on trade in app / credit app
        title={formProps.title || 'Personal Information'}
        body={formProps.body || 'Enter your contact information.'}
      />
      {/* TODO: hide first name, last name if they are already filled out */}
      {formValues.firstName === '' ? <FirstName /> : null}
      {formValues.lastName === '' ? <LastName /> : null}
      <Email />
      <Phone />
      <Communication />
    </FormContentWrapper>
  );
};
