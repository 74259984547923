import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Selector } from './Selector';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';

interface NavSelectorProps {
  title: string;
  btnIcon: IconDefinition;
  onBtnClick: () => void;
}

export default function NavSelector({ title, btnIcon, onBtnClick }: NavSelectorProps) {
  return (
    <Selector
      type="button"
      thumbnail={{ icon: btnIcon }}
      title={title}
      btnIcon={faAngleRight}
      handleClick={onBtnClick}
      size="medium"
    />
  );
}
