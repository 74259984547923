import { useMemo } from 'react';
import { BlockStatus } from 'src/types/blocks';
import { PaymentType } from 'src/types/deal';
import { useDeals } from '../DealsProvider';
import { useAuth } from '../AuthProvider';
import { useSessionContext } from '../SessionProvider';
import { useConfig } from '../config';
import { isSitePaymentHidden } from 'src/constants/price';

export const useFinanceStatus = () => {
  const config = useConfig()!;
  const { token } = useAuth();
  const { deal, activeDealId, deals } = useDeals()!;
  const { paymentOptionsVisited } = useSessionContext();

  const vehicleId = useMemo(
    () => (!token ? (deal ? deal.vin : undefined) : activeDealId),
    [activeDealId, token, deal]
  );

  // TODO: how does this package affect the finance status and visibility?
  // const isRetailingEnabled = config.packages?.retailing;

  return useMemo(() => {
    //TODO: review this
    if (isSitePaymentHidden(config)) {
      return BlockStatus.Hidden;
    }
    // Do we need a done AND disabled status?
    if (!deal.vin) {
      return BlockStatus.Disabled;
    }

    if (!deal.vin) {
      return BlockStatus.Blocked;
    }

    if (deal.paymentType === PaymentType.Leasing && !deal.isLeaseEnabled) {
      return BlockStatus.Incomplete;
    }

    if (
      deal.isDeskingLocked ||
      (vehicleId && paymentOptionsVisited?.includes(vehicleId)) ||
      (deal.vin && deals?.some(el => el.vin === deal.vin))
    ) {
      return BlockStatus.Done;
    }

    //No incomplete status for this block
    return BlockStatus.Default;
  }, [deal, paymentOptionsVisited, vehicleId, config, deals]);
};
