import { utcToZonedTime } from 'date-fns-tz';
import { WeekSchedule } from 'src/types/dealership';

export const week = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const convertHour = (hour: number, label: boolean) => {
  if (hour < 10 && !label) return `0${hour}`;
  if (hour > 12 && label) return hour - 12;
  return hour;
};

export function returnTimeOptions(schedule: WeekSchedule) {
  let closedDays: number[] = [];
  let timeOptions = {
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
  };

  for (let day in schedule) {
    let fromHours = schedule[day].fromHours;
    let toHours = schedule[day].toHours;
    let fromMinutes = schedule[day].fromMinutes;
    let toMinutes = schedule[day].toMinutes;

    if (schedule[day].isOpen) {
      for (let hours = fromHours; toHours >= hours; ++hours) {
        const startMinutes = hours === fromHours ? fromMinutes : 0;
        const stopMinutes = hours === toHours ? toMinutes : 45;

        for (let minutes = startMinutes; minutes <= stopMinutes; minutes += 15) {
          timeOptions[day].push<{ value: string; label: string }>({
            value: `${convertHour(hours, false)}:${minutes === 0 ? '00' : minutes}`,
            label: `${convertHour(hours, true)}:${minutes === 0 ? '00' : minutes} ${
              hours > 11 ? 'pm' : 'am'
            }`,
          });
        }
      }
    }

    if (schedule.hasOwnProperty(day) && !schedule[day].isOpen) {
      closedDays.push(week.indexOf(day));
    }
  }

  return { timeOptions, closedDays };
}

export function getFormattedDate(date: Date) {
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');

  return month + '/' + day + '/' + year;
}

export function getUserFriendlyDate(date: Date) {
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

export function getReadableDate(date: Date) {
  return date.toLocaleDateString('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  });
}

export function getShortReadableDate(date: Date) {
  return date.toLocaleDateString('en-US', {
    year: undefined,
    month: '2-digit',
    day: '2-digit',
  });
}

export function getReadableTime(date: Date) {
  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  });
}

export function getEditFriendlyTime(date: Date) {
  return date.toLocaleTimeString('en-US', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  });
}

export function getAppointmentDate(appointmentAt: string | null, dealerTimezone?: string) {
  if (appointmentAt && dealerTimezone) {
    return utcToZonedTime(appointmentAt, dealerTimezone);
  }
  return null;
}
