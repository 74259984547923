import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useConfig } from './config';
import { returnDealerTimezone } from 'src/helpers/useDealerTimezone';
import { Location, WeekSchedule } from 'src/types/dealership';
import { returnTimeOptions } from 'src/constants/dates';
import { useLeadLocationContext } from './LocationProvider';

interface ScheduleContextProps {
  dealerTimezone: string;
  schedule: WeekSchedule;
  dropdownOptions: {
    timeOptions: Record<string, { value: string; label: string; disabled?: boolean }[]>;
    closedDays: number[];
  };
}

const ScheduleContext = createContext<ScheduleContextProps | undefined>(undefined);

export const useSchedule = () => {
  const context = useContext(ScheduleContext);
  if (!context) {
    throw new Error('useSchedule must be used within a ScheduleProvider');
  }
  return context;
};

interface ScheduleProviderProps {
  location: Location['name'];
  children: React.ReactNode;
}

export const ScheduleProvider: React.FC<{
  children: React.ReactNode;
  location?: Location['name'];
}> = ({ location, children }: ScheduleProviderProps) => {
  const { locationForAppointment } = useLeadLocationContext();
  const locationToUse = location || locationForAppointment;

  const config = useConfig()!;

  const getLocationSchedule = useCallback(
    (location: string) => {
      return (
        config.locations.find(loc => loc.name === location)?.schedule ||
        config.locations[0].schedule
      );
    },
    [config.locations]
  );

  const schedule = useMemo(() => {
    return getLocationSchedule(locationToUse);
  }, [locationToUse, getLocationSchedule]);

  const dropdownOptions = useMemo(() => returnTimeOptions(schedule), [schedule]);

  return (
    <ScheduleContext.Provider
      // default to first location timezone
      value={{
        dealerTimezone: returnDealerTimezone(
          locationToUse
            ? config.locations.find(loc => loc.name === locationToUse)?.timezone ||
                config.locations[0].timezone
            : config.locations[0].timezone
        ),
        schedule,
        dropdownOptions,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};
