import './PageLoader.css';

export default function PageLoader({ children }: { children?: React.ReactNode }) {
  return (
    <div className="form-modal-wrapper">
      <div className="u-display-flex">
        <div className="page--loader">
          <div className="progress mod-loading">
            {children ? children : <div className="progress--bar"></div>}
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}
